import { types, flow, getEnv } from 'mobx-state-tree'
import moment from 'moment'

const DecimalPrimitive = types.custom({
    name: 'Decimal',
    fromSnapshot (value) {
        return parseFloat(value)
    },
    toSnapshot (value) {
        return value.toString()
    },
    isTargetType (value) {
        return parseFloat(value)
    },
    getValidationMessage (value) {
        if (/^-?\d+\.\d+$/.test(value)) return '' // OK
        if (/^\d+$/.test(value)) return '' // OK
        return `'${value}' doesn't look like a valid decimal number`
    }
})

export const TradeStore = types
    .model('TradeStore', {
        initiated: types.optional(types.boolean, false),
        id: types.maybeNull(types.integer),
        userId: types.maybeNull(types.integer),
        symbol: types.maybeNull(types.string),
        price: types.maybeNull(DecimalPrimitive, 0),
        amount: types.maybeNull(DecimalPrimitive, 0),
        nettProfit: types.maybeNull(DecimalPrimitive, 0),
        side: types.maybeNull(
            types.enumeration('tradeSide', [
                'RISE',
                'FALL'
            ])
        ),
        duration: types.maybeNull(types.integer, 0),
        profitPercent: types.maybeNull(DecimalPrimitive, 0),
        status: types.maybeNull(
            types.enumeration('tradeStatus', [
                'ACTIVE',
                'WIN',
                'LOSE'
            ])
        ),
        tradeOpenAt: types.maybeNull(types.Date),
        tradeEndAt: types.maybeNull(types.Date),
        createdAt: types.maybeNull(types.Date),
        lineShapeId: types.maybeNull(types.string),
        flagShapeId: types.maybeNull(types.string)
    })
    .views((self) => {
        return {
            get isExpired () {
                const now = moment()
                const tradeEndAt = moment(self.tradeEndAt)
                return now.isAfter(tradeEndAt)
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        }
    }))
