import { useState, useEffect, useMemo, useContext } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import _ from 'lodash'
import moment from 'moment'
import focusErrorInput from '@helpers/focusErrorInput'
import schema from './schema'
import styles from './TradeOptionContractForm.module.less'
import SymbolFieldset from '@components/user/option-contract/fieldset/symbol/SymbolFieldset'
import OptionAmountFieldset from '@components/user/option-contract/fieldset/amount/OptionAmountFieldset'
import OptionExpirationFieldset from '@components/user/option-contract/fieldset/duration/OptionExpirationFieldset'
import CallOptionButton from '@components/user/option-contract/button/call/CallOptionButton'
import PutOptionButton from '@components/user/option-contract/button/put/PutOptionButton'
import OptionDurationType from '@models/enum/OptionDurationType'
import container from '@container'
import convertDurationToMinute from '@helpers/convertDurationToMinute'
import TradeContext from '@contexts/trade/TradeContext'
import roundToXDigits from '@helpers/roundToXDigits'

const TradeOptionContractForm = props => {
    const [form] = Form.useForm()
    const { symbol, setIsTradeRiseBtnHovered, setIsTradeFallBtnHovered } = useContext(TradeContext)
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const [formContext, setFormContext] = useState({})
    const [successMessage, setSuccessMessage] = useState(props.successMessage)
    const reactHookFormMethods = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: props.defaultValues,
        context: formContext
    })

    const tradeService = useMemo(() => {
        return container.get('tradeService')
    }, [])

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        const selectedSymbol = props.symbolListStore.symbols.get(symbol?.id)
        if (selectedSymbol) {
            const context = {
                minAmount: selectedSymbol?.minAmount,
                maxAmount: selectedSymbol?.maxAmount
            }
            logger.info('[Symbol Min & Max Amount]', context)
            setFormContext(context)
        }
    }, [props.symbolListStore.symbols.get(symbol?.id)])

    useEffect(() => {
    }, [props])

    useEffect(() => {
    }, [symbol])

    useEffect(() => {
        setSuccessMessage(props.successMessage)
    }, [props.successMessage])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            if (!_.isEmpty(reactHookFormMethods.formState.errors)) return
            logger.info('[Trade Option Contract Form Submit Data]', symbol, data)
            const symbolId = symbol?.id
            const price = roundToXDigits(symbol.tradePrice, symbol.maxDecimalPoints)
            const amount = data.amount
            const ts = Date.now()
            const side = data.side
            let duration = convertDurationToMinute(data.duration, data.durationType)
            if (data.expiredBy === 'END_TIME') {
                const now = moment()
                const end = moment(data.endTime)
                if (end.isSameOrBefore(now)) {
                    reactHookFormMethods.setError('endTime', {
                        type: 'manual',
                        message: 'End time should be at least 1 minute from now'
                    })
                    return
                }
                const d = moment.duration(end.diff(now))
                const minutes = Math.round(d.asMinutes())
                duration = minutes
            }
            const res = await tradeService.trade(
                props.userStore.accessToken,
                symbolId,
                price,
                amount,
                duration,
                side,
                ts
            )
            logger.info('[Trade API Res]', res)
            if (res.ok) {
                Modal.success({
                    title: 'Success',
                    content: 'Your trade has been placed successfully'
                })
                reactHookFormMethods.reset()
                const data = res.data

                if (data.availableBalance) {
                    props.userStore.setData({
                        availableBalance: data.availableBalance
                    })
                }
                if (props.onSuccessSubmit) props.onSuccessSubmit(res.data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(reactHookFormMethods.watch())
                    const errors = res.data.errors
                    const errorKeysToReplace = (data.expiredBy === 'END_TIME') ? [{ from: 'duration', to: 'endTime' }] : []
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, reactHookFormMethods.setFocus, reactHookFormMethods.setError, ['endTime'], errorKeysToReplace)

                    if (nonErrorFieldMessages.length > 0) {
                        Modal.error({
                            title: 'Error',
                            content: (
                                <ul>
                                    {nonErrorFieldMessages.map((message, index) =>
                                        <li key={index}>{message}</li>
                                    )}
                                </ul>
                            )
                        })
                    }
                }
            } else {
                Modal.error({
                    title: 'Error',
                    content: (
                        <ul>
                            <li>
                                Something went wrong
                            </li>
                        </ul>
                    )
                })
            }
        } catch (error) {
            logger.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    const handleCallOptionBtnClick = () => {
        reactHookFormMethods.setValue('side', 'RISE')
        reactHookFormMethods.handleSubmit(onSubmit)()
    }

    const handlePutOptionBtnClick = () => {
        reactHookFormMethods.setValue('side', 'FALL')
        reactHookFormMethods.handleSubmit(onSubmit)()
    }

    const handleCallOptionBtnMouseEnter = () => {
        logger.debug('[Call Option Button Mouse Enter]')
        setIsTradeRiseBtnHovered(true)
    }

    const handleCallOptionBtnMouseLeave = () => {
        logger.debug('[Call Option Button Mouse Leave]')
        setIsTradeRiseBtnHovered(false)
    }

    const handlePutOptionBtnMouseEnter = () => {
        logger.debug('[Put Option Button Mouse Enter]')
        setIsTradeFallBtnHovered(true)
    }

    const handlePutOptionBtnMouseLeave = () => {
        logger.debug('[Put Option Button Mouse Leave]')
        setIsTradeFallBtnHovered(false)
    }

    return (
        <FormProvider {...reactHookFormMethods}>
            <Form
                className={cn(props.className, styles.tradeOptionContractFormWrapper)}
                style={props.style}
                layout='vertical'
                onFinish={reactHookFormMethods.handleSubmit(onSubmit)}
                autoComplete='off'
                form={form}
            >
                <SymbolFieldset />

                <OptionAmountFieldset />

                <OptionExpirationFieldset />

                <fieldset className={styles.tradeSideFieldset}>
                    <CallOptionButton
                        submitting={submitting}
                        className='mb-3'
                        onClick={handleCallOptionBtnClick}
                        onMouseEnter={handleCallOptionBtnMouseEnter}
                        onMouseLeave={handleCallOptionBtnMouseLeave}
                        profit={props.symbolListStore.symbols.get(symbol.id)?.profitPercentRise}
                    />

                    <PutOptionButton
                        submitting={submitting}
                        onClick={handlePutOptionBtnClick}
                        onMouseEnter={handlePutOptionBtnMouseEnter}
                        onMouseLeave={handlePutOptionBtnMouseLeave}
                        profit={props.symbolListStore.symbols.get(symbol.id)?.profitPercentFall}
                    />
                </fieldset>
            </Form>
        </FormProvider>
    )
}

TradeOptionContractForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onSuccessSubmit: PropTypes.func,
    defaultValues: PropTypes.object
}

TradeOptionContractForm.defaultProps = {
    onSuccessSubmit: () => {},
    defaultValues: {
        expiredBy: 'DURATION',
        durationType: OptionDurationType.MINUTE.enumKey
    }
}

export default inject('userStore', 'symbolListStore')(observer(TradeOptionContractForm))
