import { useEffect, useMemo } from 'react'
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom'
import { onSnapshot, applySnapshot } from 'mobx-state-tree'
import AsyncStorage from '@callstack/async-storage'
import AppProvider from '@contexts/app/AppProvider'
import container from '@container'
import UserPublicRoute from '@components/user/route/UserPublicRoute'
import UserPrivateRoute from '@components/user/route/UserPrivateRoute'
import RegisterPage from '@pages/register'
import LoginPage from '@pages/login'
import RequestResetPasswordPage from '@pages/request-reset-password'
import ResetPasswordPage from '@pages/reset-password'
import DashboardPage from '@pages/dashboard'
import UserTradeListPage from '@pages/trades'

const userStore = container.get('userStore')
const config = container.get('config')
const logger = container.get('logger')

onSnapshot(userStore, snapshot => {
    const obj = snapshot
    if (!config.app.isProduction && !config.app.isTesting) {
        logger.info('[Store User Data to Local Storage]', obj)
    }
    AsyncStorage.setItem('userStore', JSON.stringify(obj))
})

const App = (props) => {
    const container = useMemo(() => {
        return props.container
    }, [props.container])

    useEffect(() => {
        const load = async () => {
            const userStore = container.get('userStore')
            const userJsonStr = await AsyncStorage.getItem('userStore')
            if (userJsonStr) {
                const user = JSON.parse(userJsonStr)
                applySnapshot(userStore, user)
            }
            userStore.init()
        }
        load()
    }, [])

    return (
        <div className='fade-in'>
            <AppProvider container={container}>
                <HashRouter>
                    <Routes>
                        <Route
                            path='/'
                            element={
                                <Navigate
                                    to='/login'
                                    replace
                                />
                            }
                        />

                        <Route
                            path='/register'
                            element={
                                <UserPublicRoute restricted>
                                    <RegisterPage />
                                </UserPublicRoute>
                            }
                        />

                        <Route
                            path='/login'
                            element={
                                <UserPublicRoute restricted>
                                    <LoginPage />
                                </UserPublicRoute>
                            }
                        />

                        <Route
                            path='/request-reset-password'
                            element={
                                <UserPublicRoute restricted>
                                    <RequestResetPasswordPage />
                                </UserPublicRoute>
                            }
                        />

                        <Route
                            path='/reset-password'
                            element={
                                <UserPublicRoute restricted>
                                    <ResetPasswordPage />
                                </UserPublicRoute>
                            }
                        />

                        <Route
                            path='/dashboard'
                            element={
                                <UserPrivateRoute>
                                    <DashboardPage />
                                </UserPrivateRoute>
                            }
                        />

                        <Route
                            path='/trades'
                            element={
                                <UserPrivateRoute>
                                    <UserTradeListPage />
                                </UserPrivateRoute>
                            }
                        />
                    </Routes>
                </HashRouter>
            </AppProvider>
        </div>
    )
}

export default App
