import { string, object, number } from 'yup'
import moment from 'moment'
import OptionDurationType from '@models/enum/OptionDurationType'

const schema = object().shape({
    amount: number()
        .when(['$minAmount', '$maxAmount'], (minAmount, maxAmount, schema) => {
            return number()
                .nullable()
                .transform((value, orginalValue) => {
                    return (isNaN(value)) ? null : value
                })
                .min(minAmount, `Min amount is ${minAmount}`)
                .max(maxAmount, `Max amount is ${maxAmount}`)
                .required('Amount is a required field')
        }),
    expiredBy: string()
        .nullable()
        .required('This field is a required field')
        .oneOf(['DURATION', 'END_TIME'], 'This field is a required field'),
    durationType: string()
        .nullable()
        .required('This field is a required field')
        .oneOf([OptionDurationType.enumKeys], 'This field is a required field'),
    duration: number()
        .when(['expiredBy'], (expiredBy) => {
            if (expiredBy === 'DURATION') {
                return number()
                    .nullable()
                    .transform((value, orginalValue) => {
                        return (isNaN(value)) ? null : value
                    })
                    .min(1, 'Minimum duration is 1')
                    .required('Duration is a required field')
            }
        }).nullable(),
    endTime: string()
        .when(['expiredBy'], (expiredBy) => {
            if (expiredBy === 'END_TIME') {
                return string()
                    .nullable()
                    .required('End time is a required field')
                    .test(
                        'is-valid',
                        'Invalid datetime format',
                        (value, context) => {
                            if (value === null || value === '') {
                                return false
                            }
                            return moment(value).isValid()
                        }
                    )
            }
        }).nullable()
})

export default schema
