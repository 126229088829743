import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import numeral from 'numeral'
import { Dropdown, Menu } from 'antd'
import { DollarCircleFilled } from '@ant-design/icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { inject, observer } from 'mobx-react'
import styles from './UserAccountDropdown.module.less'
import UserAccountDropdownMenu from './menu/UserAccountDropdownMenu'

const UserAccountDropdown = (props) => {
    const [isOpened, setIsOpened] = useState(false)

    return (
        <Dropdown
            open={isOpened}
            overlay={<UserAccountDropdownMenu />}
            placement={props.placement}
            trigger={props.trigger}
            className={cn(props.className)}
            style={props.style}
            onOpenChange={(value) => setIsOpened(value)}
        >
            <div className={cn(styles.userAccountDropdownTrigger, { [styles.isOpened]: isOpened })}>
                {/* <DollarCircleFilled className={styles.icon} /> */}
                <span className={styles.accountBalance}>{numeral(props.userStore.availableBalance).format('0,0.00')} {props.userStore.availableBalanceCurrency}</span>
                <FontAwesomeIcon icon={faAngleDown} className={styles.arrow} />
            </div>
        </Dropdown>
    )
}

UserAccountDropdown.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    placement: PropTypes.string,
    trigger: PropTypes.array
}

UserAccountDropdown.defaultProps = {
    placement: 'bottomRight',
    trigger: ['click']
}

export default inject('userStore')(observer(UserAccountDropdown))
