import { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Input } from 'antd'
import { inject } from 'mobx-react'
import focusErrorInput from '@helpers/focusErrorInput'
import FormError from '@components/common/form/error/FormError'
import FormSuccess from '@components/common/form/success/FormSuccess'
import schema from './schema'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import container from '@container'

const RequestResetPasswordForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const [successMessage, setSuccessMessage] = useState(null)
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            setSuccessMessage(null)

            const res = await props.userStore.requestResetPassword(
                data.email
            )

            if (res.ok) {
                const data = res.data
                setSuccessMessage('An email has been sent to your email.')
                if (props.onSuccessSubmit) props.onSuccessSubmit(data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            logger.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            {successMessage &&
            <FormSuccess className='mb-3' message={successMessage} />}

            <Form.Item
                className='mb-4'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            name='email'
                            className='input-text-full'
                            placeholder='Email'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Request To Reset My Password
            </SubmitButton>
        </Form>
    )
}

RequestResetPasswordForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

RequestResetPasswordForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('userStore')(RequestResetPasswordForm)
