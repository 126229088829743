const listMarketHistoryEndpointPath = (symbolName, timeframe) => `/market-data/history/${symbolName}/${timeframe}`

class MarketService {
    api = null

    constructor (api) {
        this.api = api
    }

    async getHistory (symbolName, timeframe) {
        const res = await this.api.get(listMarketHistoryEndpointPath(symbolName, timeframe))
        return res
    }
}

export default MarketService
