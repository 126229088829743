import { Enumify } from 'enumify'

export default class OptionDurationType extends Enumify {
    value = null

    constructor (value = null, label = null, unit = null) {
        super()
        this.value = value
        this.label = label
        this.unit = unit
    }

    static of (type) {
        return OptionDurationType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return OptionDurationType.TICK
            case 1: return OptionDurationType.MINUTE
            case 2: return OptionDurationType.HOUR
        }
        return null
    }
}

OptionDurationType.TICK = new OptionDurationType(0, 'Ticks')
OptionDurationType.MINUTE = new OptionDurationType(1, 'Minutes', 'minute')
OptionDurationType.HOUR = new OptionDurationType(2, 'Hours', 'hour')
OptionDurationType._ = OptionDurationType.closeEnum()
