const convertDurationToMinute = (duration, unit) => {
    switch (unit) {
        case 'MINUTE':
            return duration
        case 'HOUR':
            return duration * unit * 60
    }
}

export default convertDurationToMinute
