const appConfig = {
    isProduction: process.env.REACT_APP_APP_ENV === 'production' || false,
    isTesting: process.env.REACT_APP_APP_ENV === 'test' || false,
    appEnv: process.env.REACT_APP_APP_ENV || 'local',
    appName: process.env.REACT_APP_APP_NAME,
    appUrl: process.env.REACT_APP_APP_URL || 'http://localhost:3000',
    apiUrl: (process.env.REACT_APP_MOCK_API === 'true' && process.env.REACT_APP_MOCK_API_WITH_SERVER === 'true') ? process.env.REACT_APP_MOCK_API_URL : process.env.REACT_APP_API_URL || 'http://localhost:3001',
    mockApi: process.env.REACT_APP_MOCK_API === 'true' || false,
    mockApiWithServer: process.env.REACT_APP_MOCK_API_WITH_SERVER === 'true' || false,
    apiWebsocketUrl: process.env.REACT_APP_API_WEBSOCKET_URL,
    mockTradeCompleteEvent: process.env.REACT_APP_MOCK_TRADE_COMPLETE_EVENT === 'true' || false,
    logLevel: process.env.REACT_APP_LOG_LEVEL || 'TRACE',
    defaultTheme: 'DARK'
}

export default appConfig
