const registerEndpointPath = '/user/register'
const loginEndpointPath = '/user/login'
const checkTokenEndpointPath = '/user/check-token'
const requestResetPasswordEndpointPath = '/user/forgot-password'
const resetPasswordEndpointPath = '/user/reset-password'
const getAccountSummaryEndpointPath = '/user/account/summary'
class UserService {
    api = null

    constructor (api) {
        this.api = api
    }

    async register (
        lastName,
        firstName,
        country,
        email,
        password
    ) {
        const res = await this.api.post(registerEndpointPath, {
            lastName,
            firstName,
            country,
            email,
            password
        })
        return res
    }

    async login (email, password) {
        const res = await this.api.post(loginEndpointPath, {
            email, password
        })
        return res
    }

    async getAccountSummary (accessToken) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(getAccountSummaryEndpointPath)
        return res
    }

    async checkToken (accessToken) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(checkTokenEndpointPath)
        return res
    }

    async requestResetPassword (email) {
        const res = await this.api.post(requestResetPasswordEndpointPath, {
            email
        })
        return res
    }

    async resetPassword (token, password) {
        const res = await this.api.post(resetPasswordEndpointPath, {
            token,
            password
        })
        return res
    }
}

export default UserService
