class DataSubscriptionManager {
    subscriptionChannels = new Map()
    logger = null
    updatedAt = null

    constructor (logger) {
        this.logger = logger
    }

    addSubsciber = (channelId, subscriber) => {
        let channel = this.subscriptionChannels.get(channelId)
        if (channel) {
            channel.subscribers.set(subscriber.id, subscriber)
            this.logger.info('[Data Subscription Manager]: Add new subscriber:', {
                channelId,
                subscriber
            })
            return
        }
        channel = {
            subscribers: new Map([
                [subscriber.id, subscriber]
            ])
        }
        this.subscriptionChannels.set(channelId, channel)
        this.logger.info('[Data Subscription Manager]: Create new channel:', channelId)
        this.updatedAt = Date.now()
    }

    removeSubsciber = (channelId, subscriberId) => {
        if (channelId) {
            const channel = this.subscriptionChannels.get(channelId)
            if (channel) {
                const subscriber = channel.subscribers.get(subscriberId)

                if (subscriber) {
                    channel.subscribers.delete(subscriberId)
                    this.logger.info('[Data Subscription Manager]: Remove subcriber', subscriberId)

                    if (channel.subscribers.size === 0) {
                        this.logger.info('[Data Subscription Manager]: Remove channel', channel)
                        this.subscriptionChannels.delete(channelId)
                    }
                    this.updatedAt = Date.now()
                }
            }
        } else {
            for (const channelId of this.subscriptionChannels.keys()) {
                const channel = this.subscriptionChannels.get(channelId)
                const subscriber = channel.subscribers.get(subscriberId)

                if (subscriber) {
                    channel.subscribers.delete(subscriberId)
                    this.logger.info('[Data Subscription Manager]: Remove subcriber', subscriberId)

                    if (channel.subscribers.size === 0) {
                        this.logger.info('[Data Subscription Manager]: Remove channel', channel)
                        this.subscriptionChannels.delete(channelId)
                    }
                    this.updatedAt = Date.now()
                }
            }
        }
    }
}

export default DataSubscriptionManager
