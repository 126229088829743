const listSymbolEndpointPath = '/symbol/config/all'

class SymbolService {
    api = null

    constructor (api) {
        this.api = api
    }

    async getAll () {
        const res = await this.api.get(listSymbolEndpointPath)
        return res
    }
}

export default SymbolService
