import { create } from 'apisauce'
import qs from 'qs'

class WebClient {
    httpClient = null
    authToken = null

    constructor (options) {
        this.httpClient = create(options)
        this.httpClient.addRequestTransform(this.requestTransformFunc)
    }

    requestTransformFunc = async (request) => {
        if (this.authToken) {
            request.headers.Authorization = 'Bearer ' + this.authToken
        }

        if (request.method?.toLowerCase() === 'post' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            request.data = qs.stringify(request.data)
        } else if (request.method?.toLowerCase() === 'put' && request.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            request.data = qs.stringify(request.data)
        } else if (request.method?.toLowerCase() === 'get' && request.data) {
            request.data = qs.stringify(request.data)
            request.url += '?' + request.data
        } else if (request.method?.toLowerCase() === 'delete' && request.data) {
            request.url += '?' + request.data
        }

        return request
    }

    post = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.post(...args)
                resolve(response)
            })()
        })
    }

    put = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.put(...args)
                resolve(response)
            })()
        })
    }

    delete = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.delete(...args)
                resolve(response)
            })()
        })
    }

    get = async (...args) => {
        return new Promise((resolve) => {
            (async () => {
                const response = await this.httpClient.get(...args)
                resolve(response)
            })()
        })
    }

    setAuthToken = (authToken) => {
        this.authToken = authToken
    }
}

export default WebClient
