import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Popover } from 'antd'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import styles from './OptionDurationTypeSelect.module.less'
import OptionDurationType from '@models/enum/OptionDurationType'

const options = [
    // { value: OptionDurationType.TICK.enumKey, label: OptionDurationType.of('TICK').label },
    { value: OptionDurationType.MINUTE.enumKey, label: OptionDurationType.of('MINUTE').label },
    { value: OptionDurationType.HOUR.enumKey, label: OptionDurationType.of('HOUR').label }
]

const OptionDurationTypeSelect = (props) => {
    const [value, setValue] = useState(props.value)
    const [selectedOption, setSelectedOption] = useState(null)
    const [isPopoverOpened, setIsPopoverOpened] = useState(false)

    useEffect(() => {
        const option = _.find(options, { value: props.value })
        if (option) {
            setSelectedOption(option)
        }
    }, [props.value])

    const handleBtnTriggerClick = () => {
        setIsPopoverOpened(!isPopoverOpened)
    }

    const handleOptionClick = (option) => {
        setSelectedOption(option)
        setValue(option.value)
        props.onChange(option)
        setIsPopoverOpened(false)
    }

    return (
        <Popover
            overlayClassName={styles.optionDurationPopover}
            placement='leftTop'
            open={isPopoverOpened}
            content={
                <ul className={styles.durationTypeOptionList}>
                    {options.map((option, index) => {
                        return (
                            <li
                                key={index}
                                className={cn({ [styles.active]: value === option.value })}
                                onClick={() => handleOptionClick(option)}
                            >
                                {option.label}
                            </li>
                        )
                    })}
                </ul>
            }
        >
            <button
                type='button'
                className={cn(styles.btnDurationDropdownTrigger, props.className)}
                style={props.style}
                onClick={handleBtnTriggerClick}
            >
                <FontAwesomeIcon icon={faArrowLeft} className={styles.iconLeft} />
                <span>{selectedOption?.label}</span>
            </button>
        </Popover>
    )
}

OptionDurationTypeSelect.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func
}

OptionDurationTypeSelect.defaultProps = {
}

export default OptionDurationTypeSelect
