import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import AsyncStorage from '@callstack/async-storage'
import ThemeContext from './ThemeContext'
import appContainer from '../../container'

const { Provider } = ThemeContext

const config = appContainer.get('config')

const ThemeProvider = props => {
    const config = useMemo(() => {
        return appContainer.get('config')
    }, [])

    const localStorageTheme = useMemo(() => {
        const theme = localStorage.getItem('theme')
        return theme
    }, [])

    const [theme, setTheme] = useState(localStorageTheme || config.app.defaultTheme)

    useEffect(() => {
        const currentThemeCsslinkElement = document.getElementById('current-theme-style')
        if (theme === 'DARK') {
            const darkThemeCsslinkElement = document.getElementById('theme-prefetch-dark')
            currentThemeCsslinkElement.href = darkThemeCsslinkElement.href
            document.body.classList.remove('light')
            document.body.classList.add('dark')
        } else {
            const lightThemeCsslinkElement = document.getElementById('theme-prefetch-light')
            currentThemeCsslinkElement.href = lightThemeCsslinkElement.href
            document.body.classList.remove('dark')
            document.body.classList.add('light')
        }
        AsyncStorage.setItem('theme', theme)
    }, [theme])

    return (
        <Provider value={{ theme, setTheme }}>
            {props.children}
        </Provider>
    )
}

ThemeProvider.propTypes = {
}

ThemeProvider.defaultProps = {
}

export default ThemeProvider
