const tradeEndpointPath = '/trade/new'
const getRecentListEndpointPath = '/trade/list/recent'
const getAllEndpointPath = '/trade/list'
const getActiveListEndpointPath = '/trade/list/active'
const getCompletedListEndpointPath = '/trade/list/completed'

class TradeService {
    api = null

    constructor (api) {
        this.api = api
    }

    async trade (accessToken, symbol, price, amount, duration, side, ts) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.post(tradeEndpointPath, {
            symbol, price, amount, duration, side, ts
        })
        return res
    }

    async getRecentList (accessToken) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(getRecentListEndpointPath)
        return res
    }

    async getAll (accessToken, params = {}) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(getAllEndpointPath, params)
        return res
    }

    async getActiveList (accessToken, params = {}) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(getActiveListEndpointPath, params)
        return res
    }

    async getCompletedList (accessToken, params = {}) {
        this.api.setAuthToken(accessToken)
        const res = await this.api.get(getCompletedListEndpointPath, params)
        return res
    }
}

export default TradeService
