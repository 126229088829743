import React, { useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ResetPasswordForm from '../ResetPasswordForm'
import styles from './ResetPasswordFormWrapper.module.less'

const ResetPasswordFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1>Reset Password</h1>

            <ResetPasswordForm
                className='mt-5 mb-3 w-full'
                defaultValues={props.defaultValues}
                onSuccessSubmit={props.onSuccessSubmit}
            />
        </div>
    )
}

ResetPasswordFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    defaultValues: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

ResetPasswordFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default ResetPasswordFormWrapper
