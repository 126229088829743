import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './FormError.module.less'

const FormError = props => {
    return (
        <>
            {(props.messages.length !== 0) &&
            <div className={cn(props.className, styles.formErrorWrapper)}>
                <ul>
                    {props.messages.map((message, index) =>
                        <li key={index}>
                            {message}
                        </li>
                    )}
                </ul>
            </div>}
        </>
    )
}

FormError.propTypes = {
    className: PropTypes.string,
    messages: PropTypes.array.isRequired
}

FormError.defaultProps = {
    messages: []
}

export default FormError
