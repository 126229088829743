import { useRef, useMemo, useState, useEffect, useContext, useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import numeral from 'numeral'
import moment from 'moment'
import 'moment-timezone'
import { Spin, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxArchive } from '@fortawesome/free-solid-svg-icons'
import styles from './DashboardChart.module.less'
import { widget as Widget } from '../../../../charting_library'
import ThemeContext from '@contexts/theme/ThemeContext'
import TradeContext from '@contexts/trade/TradeContext'
import getTradingViewWidgetOverride from '@helpers/getTradingViewWidgetOverride'
import getTimeframeFromResolution from '@helpers/datafeed/getTimeframeFromResolution'
import symbolTypes from '@constants/symbolTypes'
import container from '@container'
import TradeStatus from '@models/enum/TradeStatus'
import TradeSide from '@models/enum/TradeSide'
import ChartType from '@models/enum/ChartType'
import DataSubscriptionManager from '@helpers/DataSubscriptionManager'
import getNextBarTime from '@helpers/datafeed/getNextBarTime'

const lastBarsCache = new Map()
const lastTicksCache = new Map()
const marketDataHistoryCache = new Map()

const DashboardChart = (props) => {
    const refChartContainer = useRef(null)
    const { theme } = useContext(ThemeContext)
    const { symbol, setSymbol, isTradeRiseBtnHovered, isTradeFallBtnHovered } = useContext(TradeContext)
    const [isMounted, setIsMounted] = useState(false)
    const [loading, setLoading] = useState(true)
    const [toShowNoMarketAlert, setToShowNoMarketAlert] = useState(false)
    const [isIntervalTick, setIsIntervalTick] = useState(true)
    const [chartType, setChartType] = useState(ChartType.LINE.value)
    const [tradeRiseRectangleShapeId, setTradeRiseRectangleShapeId] = useState(null)
    const [tradeFallRectangleShapeId, setTradeFallRectangleShapeId] = useState(null)

    const config = useMemo(() => {
        return container.get('config')
    }, [])

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    const dataSubscriptionManager = useMemo(() => {
        return new DataSubscriptionManager(logger)
    }, [])

    const apiWebsocketClient = useMemo(() => {
        return container.get('apiWebsocketClient')
    }, [])

    const configurationData = useMemo(() => {
        return {
            supported_resolutions: ['1T', '1', '5', '15', '30', '60', '120', '240', '1D'],
            exchanges: [
                {
                    value: config.app.appName,
                    name: config.app.appName,
                    desc: config.app.appName
                }
            ],
            symbols_types: symbolTypes
        }
    }, [])

    const datafeed = useMemo(() => {
        return {
            onReady: (callback) => {
                logger.info('[Datafeed] onReady: Method call')
                callback(configurationData)
            },
            searchSymbols: async (
                userInput,
                exchange,
                symbolType,
                onResultReadyCallback
            ) => {
                // logger.info('[Datafeed] searchSymbols: Method call')
                // let symbols = await getSymbolsFromStore()
                // symbols = symbols.map((symbol) => {
                //     return {
                //         id: symbol.id,
                //         symbol: symbol.name,
                //         name: symbol.name,
                //         full_name: symbol.name,
                //         description: symbol.name,
                //         exchange: config.app.appName,
                //         type: symbol.type
                //     }
                // })
                // const newSymbols = symbols.filter(symbol => {
                //     const isSymbolTypeValid = symbolType === '' || symbolType === 'all' || symbol.type === symbolType
                //     const isFullSymbolContainsInput = symbol.full_name
                //         .toLowerCase()
                //         .indexOf(userInput.toLowerCase()) !== -1
                //     return isSymbolTypeValid && isFullSymbolContainsInput
                // })
                // onResultReadyCallback(newSymbols)
            },
            resolveSymbol: async (
                symbolName,
                onSymbolResolvedCallback,
                onResolveErrorCallback
            ) => {
                logger.info('[Datafeed] resolveSymbol: Method call', symbolName)
                let symbols = getSymbolsFromStore()
                symbols = symbols.map((symbol) => {
                    return {
                        id: symbol.id,
                        symbol: symbol.name,
                        name: symbol.name,
                        full_name: symbol.name,
                        description: symbol.name,
                        exchange: config.app.appName,
                        type: symbol.type,
                        max_decimal_points: symbol.maxDecimalPoints
                    }
                })
                /* eslint-disable camelcase */
                const symbolItem = symbols.find(({ full_name }) => full_name === symbolName)
                if (!symbolItem) {
                    logger.info('[Datafeed] resolveSymbol: Cannot resolve symbol', symbolName)
                    onResolveErrorCallback('Symbol not found')
                    return
                }
                const symbolInfo = {
                    id: symbolItem.id,
                    name: symbolItem.name,
                    description: symbolItem.description,
                    type: symbolItem.type,
                    session: '24x7',
                    timezone: 'Etc/UTC',
                    exchange: symbolItem.exchange,
                    supported_resolutions: configurationData.supported_resolutions,
                    data_status: 'streaming',
                    has_intraday: true,
                    intraday_multipliers: ['1', '5', '15', '30', '60', '120', '240'],
                    format: 'price',
                    minmov: 1,
                    pricescale: Math.pow(10, symbolItem.max_decimal_points),
                    max_decimal_points: symbolItem.max_decimal_points,
                    has_ticks: true
                }

                logger.info('[Datafeed] resolveSymbol: Symbol resolved', symbolName)
                onSymbolResolvedCallback(symbolInfo)
            },
            getBars: async (
                symbolInfo,
                resolution,
                periodParams,
                onHistoryCallback,
                onErrorCallback
            ) => {
                const { from, to, countBack, firstDataRequest } = periodParams
                logger.info('[Datafeed] getBars: Method call', symbolInfo, resolution, from, to, moment(from * 1000).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), moment(to * 1000).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), countBack, firstDataRequest)
                try {
                    const marketService = container.get('marketService')
                    const timeframe = getTimeframeFromResolution(resolution)

                    let data = marketDataHistoryCache.get(symbolInfo.ticker)

                    const isTick = resolution === '1T'

                    if (firstDataRequest) {
                        const res = await marketService.getHistory(symbolInfo.id, timeframe)
                        if (!res.ok) {
                            onHistoryCallback([], { noData: true })
                            return
                        }

                        data = res.data.reverse()
                        const latestItem = data[data.length - 1]

                        if (!isTick) {
                            const [dateTime, open, high, low, close] = latestItem
                            const tradePrice = close
                            setSymbol({
                                id: symbolInfo.id,
                                name: symbolInfo.name,
                                tradePrice,
                                maxDecimalPoints: symbolInfo.max_decimal_points
                            })
                        } else {
                            const [dateTime, price] = latestItem
                            const tradePrice = price
                            setSymbol({
                                id: symbolInfo.id,
                                name: symbolInfo.name,
                                tradePrice,
                                maxDecimalPoints: symbolInfo.max_decimal_points
                            })
                        }
                    }

                    const bars = []
                    data.forEach(item => {
                        const [dateTime] = item
                        const timestamp = moment(dateTime).unix()
                        if (timestamp >= from && timestamp < to) {
                            if (!isTick) {
                                const [dateTime, open, high, low, close] = item
                                bars.push({
                                    time: timestamp * 1000,
                                    open,
                                    high,
                                    low,
                                    close
                                })
                            } else {
                                const [dateTime, price] = item
                                bars.push({
                                    time: timestamp * 1000,
                                    close: price
                                })
                            }
                        }
                    })

                    const noData = bars.length === 0 && firstDataRequest
                    if (noData) {
                        data.forEach(item => {
                            const [dateTime] = item
                            const timestamp = moment(dateTime).unix()
                            if (!isTick) {
                                const [dateTime, open, high, low, close] = item
                                bars.push({
                                    time: timestamp * 1000,
                                    open,
                                    high,
                                    low,
                                    close
                                })
                            } else {
                                const [dateTime, price] = item
                                bars.push({
                                    time: timestamp * 1000,
                                    close: price
                                })
                            }
                        })
                    }

                    if (firstDataRequest) {
                        marketDataHistoryCache.set(symbolInfo.ticker, data)
                        if (!isTick) {
                            lastBarsCache.set(symbolInfo.ticker, {
                                ...bars[bars.length - 1]
                            })
                        } else {
                            lastTicksCache.set(symbolInfo.ticker, {
                                ...bars[bars.length - 1]
                            })
                        }
                    }

                    logger.info(`[Datafeed] getBars: returned ${bars.length} bar(s)`)
                    onHistoryCallback(bars, { noData })
                } catch (error) {
                    logger.info('[Datafeed] getBars: Get error', error)
                    onErrorCallback(error)
                }
            },
            subscribeBars: (
                symbolInfo,
                resolution,
                onRealtimeCallback,
                subscriberUID,
                onResetCacheNeededCallback
            ) => {
                logger.info('[Datafeed] subscribeBars: Method call with subscriberUID:', subscriberUID)
                const channelId = symbolInfo.id
                const subscriber = {
                    id: subscriberUID,
                    symbolInfo,
                    resolution,
                    callback: onRealtimeCallback
                }

                dataSubscriptionManager.addSubsciber(channelId, subscriber)
            },
            unsubscribeBars: (subscriberUID) => {
                logger.info('[Datafeed] unsubscribeBars: Method call with subscriberUID:', subscriberUID)
                dataSubscriptionManager.removeSubsciber(null, subscriberUID)
            }
        }
    }, [])

    let tvWidget = useMemo(() => {
        if (!isMounted) return
        if (toShowNoMarketAlert) return
        const widgetOptions = {
            // debug: true,
            symbol: symbol.name,
            datafeed: datafeed,
            interval: '1T',
            container: refChartContainer.current,
            library_path: '/charting_library/',
            timezone: (moment.tz.guess() === 'Asia/Kuala_Lumpur') ? 'Asia/Singapore' : moment.tz.guess(),
            locale: 'en',
            fullscreen: false,
            autosize: true,
            disabled_features: ['timezone_menu', 'header_symbol_search'],
            enabled_features: ['tick_resolution'],
            theme,
            overrides: getTradingViewWidgetOverride(theme),
            settings_overrides: getTradingViewWidgetOverride(theme),
            custom_css_url: (theme === 'DARK') ? '/css/chart/themes/dark.css' : '/css/chart/themes/light.css'
        }

        const tvWidget = new Widget(widgetOptions)

        tvWidget.onChartReady(() => {
            setLoading(false)
            logger.info('[Chart Ready]')

            tvWidget.activeChart().setChartType(chartType)
        })

        return tvWidget
    }, [isMounted])

    useEffect(() => {
        const load = async () => {
            setSymbol({
                id: null,
                name: null,
                maxDecimalPoints: null
            })
            const symbols = await getAllSymbols()
            updateSymbolListStore(symbols)

            if (symbols.length > 0) {
                setSymbol({
                    id: symbols[0].symbol,
                    name: symbols[0].name,
                    maxDecimalPoints: symbols[0].maxDecimalPoints
                })
                tvWidget = null
                setIsMounted(true)
                setLoading(true)
                return
            }

            tvWidget = null
            setIsMounted(true)
            setLoading(false)
            setToShowNoMarketAlert(true)
        }
        setUpReceivePriceChangeListener()
        load()
    }, [])

    useEffect(() => {
        if (!tvWidget) return
        tvWidget.changeTheme(theme).then(() => {
            tvWidget.chart().applyOverrides(getTradingViewWidgetOverride(theme))
        })
    }, [theme])

    useEffect(() => {
        if (!loading && tvWidget) {
            const handleIntervalChange = (interval, timeframeObj) => {
                const isTick = interval === '1T'
                logger.info('[Chart Interval Change]', {
                    interval,
                    chartType
                })
                setIsIntervalTick(isTick)

                // if ((chartType !== ChartType.LINE.value && chartType !== ChartType.AREA.value && chartType !== ChartType.BASELINE.value) && isTick) {
                //     alertInvalidChartTypeForTick()
                // }

                setTimeout(() => {
                    if (isTick) {
                        tvWidget.activeChart().setChartType(ChartType.LINE.value)
                    } else {
                        tvWidget.activeChart().setChartType(ChartType.CANDLE.value)
                    }
                }, 100)
            }

            tvWidget.activeChart().onIntervalChanged().unsubscribeAll(null)
            tvWidget.activeChart().onIntervalChanged().subscribe(null, handleIntervalChange)
        }
    }, [loading, chartType])

    useEffect(() => {
        if (!loading && tvWidget) {
            const handleChartTypeChange = (chartType) => {
                logger.info('[Chart Type Change]', {
                    chartType,
                    isIntervalTick
                })

                if ((chartType !== ChartType.LINE.value && chartType !== ChartType.AREA.value && chartType !== ChartType.BASELINE.value) && isIntervalTick) {
                    alertInvalidChartTypeForTick()
                }

                setChartType(chartType)
            }

            tvWidget.activeChart().onChartTypeChanged().unsubscribeAll(null)
            tvWidget.activeChart().onChartTypeChanged().subscribe(null, handleChartTypeChange)
        }
    }, [loading, isIntervalTick])

    useEffect(() => {
        if (loading || toShowNoMarketAlert) return
        const dashboardChartElement = document.getElementById('dashboard-chart')
        const iframeElement = dashboardChartElement.querySelector('iframe')
        const linkElement = iframeElement.contentWindow.document.querySelector('link[href^="/css/chart/themes"]')
        if (linkElement) {
            linkElement.href = (theme === 'DARK') ? '/css/chart/themes/dark.css' : '/css/chart/themes/light.css'
        }
    }, [theme, loading, toShowNoMarketAlert])

    useEffect(() => {
        if (!symbol.id) return
        if (!loading && tvWidget) {
            const symbolString = tvWidget.activeChart().symbol()
            const info = symbolString.split(':')

            if (info.length >= 2) {
                logger.info('[Symbol Info]', info[1], symbol.name)
                if (info[1] !== symbol.name) {
                    logger.info('[Change Trading View Symbol]', symbol.name)
                    tvWidget.activeChart().setSymbol(symbol.name)
                }
            }
        }
        const apiWebsocketClient = container.get('apiWebsocketClient')
        apiWebsocketClient.removeCallbacks(['onReceivePriceTick-2'])
        apiWebsocketClient.addCallback('onReceivePriceTick-2', 'binavictory.service.api.pub', (message) => {
            const { payload, type } = message.body
            if (type !== 'price.tick') return
            const [symbolId, tradePrice, timestamp] = payload
            if (symbolId === symbol.id) {
                setSymbol({
                    ...symbol,
                    tradePrice
                })
                const toSkipCreate = true
                drawRecentTradeLines(props.recentTradeListStore.trades, toSkipCreate)
            }
        })

        apiWebsocketClient.addCallback('onReceiveTradeComplete-2', `binavictory.service.api.tradeEvent.${props.userStore.id}`, (message) => {
            const { payload, type } = message.body
            if (type !== 'trade.complete') return

            const trade = props.recentTradeListStore.trades.get(payload.tradeId)
            if (trade) {
                tvWidget.activeChart().removeEntity(trade.lineShapeId)
                createTrendLineShape(trade)
                createCompletedFlagShape(trade)
            }
        })
    }, [symbol])

    useEffect(() => {
        if (!isMounted || loading) return
        logger.info('[Recent Trade List Size]', props.recentTradeListStore.trades.size)
        const size = props.recentTradeListStore.trades.size
        if (size > 0) {
            const trades = new Map(
                Array.from(props.recentTradeListStore.trades).filter(([key, trade]) => {
                    if (trade.status === TradeStatus.ACTIVE.enumKey && !trade.isExpired) {
                        return true
                    }
                    return false
                })
            )
            drawRecentTradeLines(trades)
        }
    }, [isMounted, loading, props.recentTradeListStore.lastPlaceTradeAt])

    useEffect(() => {
        if (isTradeRiseBtnHovered && tvWidget && symbol?.tradePrice) {
            deleteTradeRiseRectangleShape()
            const shapeId = drawTradeRiseRectangleShape()
            setTradeRiseRectangleShapeId(shapeId)
        }
    }, [isTradeRiseBtnHovered, symbol])

    useEffect(() => {
        if (!isTradeRiseBtnHovered && tvWidget && tradeRiseRectangleShapeId) {
            deleteTradeRiseRectangleShape()
        }
    }, [isTradeRiseBtnHovered, tradeRiseRectangleShapeId])

    useEffect(() => {
        if (isTradeFallBtnHovered && tvWidget && symbol?.tradePrice) {
            deleteTradeFallRectangleShape()
            const shapeId = drawTradeFallRectangleShape()
            setTradeFallRectangleShapeId(shapeId)
        }
    }, [isTradeFallBtnHovered, symbol])

    useEffect(() => {
        if (!isTradeFallBtnHovered && tvWidget && tradeFallRectangleShapeId) {
            deleteTradeFallRectangleShape()
        }
    }, [isTradeFallBtnHovered, tradeFallRectangleShapeId])

    const setUpReceivePriceChangeListener = () => {
        apiWebsocketClient.addCallback('onReceivePriceTick-1', 'binavictory.service.api.pub', (message) => {
            const { payload, type } = message.body
            if (type !== 'price.tick') return
            const [symbol, tradePrice, timestamp] = payload

            const subscriptionChannels = dataSubscriptionManager.subscriptionChannels

            const channelString = symbol
            const channel = subscriptionChannels.get(channelString)
            if (channel === undefined) {
                return
            }

            logger.info('[Channel]', channel)
            logger.info('[Price Tick]', symbol, tradePrice, timestamp, moment(timestamp).utc().format('YYYY-MM-DDTHH:mm:ss.S[Z]'))

            for (const [key, value] of channel.subscribers.entries()) {
                const subscriber = value
                logger.info('[Subscriber]', subscriber)

                if (subscriber.resolution !== '1T') {
                    const lastBar = lastBarsCache.get(subscriber.symbolInfo.ticker)
                    logger.info('[Last Bar]', lastBar)
                    const nextBarTime = getNextBarTime(lastBar.time, subscriber.resolution)
                    logger.info('[Next Bar Time]', nextBarTime)

                    let bar
                    if (timestamp > nextBarTime) {
                        bar = {
                            time: nextBarTime,
                            open: tradePrice,
                            high: tradePrice,
                            low: tradePrice,
                            close: tradePrice
                        }
                        logger.info('[Datafeed] Generate New Bar]', bar)
                    } else {
                        bar = {
                            ...lastBar,
                            high: Math.max(lastBar.high, tradePrice),
                            low: Math.min(lastBar.low, tradePrice),
                            close: tradePrice
                        }
                        logger.info('[Datafeed] Update the latest bar by price]', tradePrice)
                    }

                    lastBarsCache.set(subscriber.symbolInfo.ticker, bar)
                    subscriber.callback(bar)
                } else {
                    const tick = {
                        time: timestamp,
                        close: tradePrice
                    }
                    logger.info('[Datafeed] Generate New Tick]', tick)

                    lastTicksCache.set(subscriber.symbolInfo.ticker, tick)
                    subscriber.callback(tick)
                }
            }
        })
    }

    const getAllSymbols = useCallback(async () => {
        try {
            const symbolService = container.get('symbolService')
            const res = await symbolService.getAll()
            const symbols = res.ok ? res.data.config : []
            return symbols
        } catch (e) {
            logger.error('Failed to get all symbols', e)
            return []
        }
    }, [])

    const alertInvalidChartTypeForTick = () => {
        Modal.warning({
            title: 'Invalid Chart Type',
            content: (
                <p>
                    The chart type you select is not available for tick interval.
                    Please select line, area or baseline type.
                </p>
            )
        })
    }

    const updateSymbolListStore = (symbols) => {
        props.symbolListStore.reset()
        symbols.forEach((symbol) => {
            props.symbolListStore.add({
                ...symbol,
                id: symbol.symbol,
                tradingRestStartAt: (symbol.tradingRestStartAt) ? new Date(symbol.tradingRestStartAt) : null,
                tradingRestEndAt: (symbol.tradingRestEndAt) ? new Date(symbol.tradingRestEndAt) : null,
                dailyRestStartAt: (symbol.dailyRestStartAt) ? new Date(symbol.dailyRestStartAt) : null,
                dailyRestEndAt: (symbol.dailyRestEndAt) ? new Date(symbol.dailyRestEndAt) : null
            })
        })
    }

    const getSymbolsFromStore = () => {
        return Array.from(props.symbolListStore.symbols.values())
    }

    const drawRecentTradeLines = (trades, toSkipCreate = false) => {
        trades.forEach((trade) => {
            if (trade.lineShapeId) {
                if (!trade.isExpired) {
                    // Get shape
                    logger.info(`[Get Shape for Trade ID - ${trade.id}]`, {
                        shapeId: trade.lineShapeId,
                        tradeOpenAt: trade.tradeOpenAt,
                        tradeEndAt: trade.tradeEndAt
                    })

                    const from = moment(trade.tradeOpenAt).unix()
                    const to = Date.now() / 1000

                    const lineShape = tvWidget.activeChart().getShapeById(trade.lineShapeId)
                    const points = [
                        { time: from, price: trade.price },
                        { time: to, price: trade.price }
                    ]
                    lineShape.setPoints(points)
                } else {
                    tvWidget.activeChart().removeEntity(trade.lineShapeId)
                    createTrendLineShape(trade)
                    createCompletedFlagShape(trade)
                }
            } else {
                if (!toSkipCreate) {
                    // Create shape
                    logger.info(`[Create Shape for Trade ID - ${trade.id}]`, {
                        tradeOpenAt: trade.tradeOpenAt,
                        tradeEndAt: trade.tradeEndAt
                    })

                    createTrendLineShape(trade)
                    createTradeSideShape(trade)
                    if (trade.isExpired) {
                        createCompletedFlagShape(trade)
                    }
                }
            }
        })
    }

    const createTrendLineShape = (trade) => {
        const from = moment(trade.tradeOpenAt).unix()
        let to = Date.now() / 1000

        if (trade.isExpired) {
            to = moment(trade.tradeEndAt).unix()
        }

        const points = [
            { time: from, price: trade.price },
            { time: to, price: trade.price }
        ]

        const lineShapeId = tvWidget.activeChart().createMultipointShape(
            points,
            {
                shape: 'trend_line',
                lock: true,
                disableSelection: true,
                disableSave: true,
                disableUndo: true,
                text: 'text',
                overrides: {
                    linecolor: '#ebe4e4'
                }
            }
        )

        if (lineShapeId) {
            tvWidget.activeChart().getShapeById(lineShapeId).bringToFront()
        }

        trade.setData({
            lineShapeId
        })
    }

    const createTradeSideShape = (trade) => {
        const from = moment(trade.tradeOpenAt).unix()
        const isRise = trade.side === TradeSide.RISE.enumKey
        const tradeSideShapeId = tvWidget.activeChart().createShape(
            {
                time: from, price: trade.price
            },
            {
                shape: isRise ? 'arrow_up' : 'arrow_down'
            }
        )

        if (tradeSideShapeId) {
            tvWidget.activeChart().getShapeById(tradeSideShapeId).bringToFront()
        }
    }

    const createCompletedFlagShape = (trade) => {
        if (!trade.flagShapeId) {
            logger.info(`[Create Flag Shape for Trade ID - ${trade.id}]`, {
                tradeOpenAt: trade.tradeOpenAt,
                tradeEndAt: trade.tradeEndAt
            })

            const flagShapeId = tvWidget.activeChart().createShape(
                {
                    time: moment(trade.tradeEndAt).unix(), price: (trade.price)
                },
                {
                    shape: 'flag',
                    overrides: {
                        backgroundColor: '#ff444f'
                    }
                }
            )

            if (flagShapeId) {
                tvWidget.activeChart().getShapeById(flagShapeId).bringToFront()
            }

            trade.setData({
                flagShapeId
            })
        }
    }

    const drawTradeRiseRectangleShape = () => {
        try {
            logger.debug('[Draw Trade Rise Rectangle Shape]')
            const activeChart = tvWidget.activeChart()
            const panes = activeChart.getPanes()
            const pane = panes?.find((pane) => pane.hasMainSeries())
            const rightScales = pane?.getRightPriceScales()
            const rightScale = rightScales?.find((scale) => scale.hasMainSeries())

            if (!rightScale) return

            const priceRange = rightScale?.getVisiblePriceRange()
            logger.info('[Price Range]', priceRange)
            const timeRange = activeChart.getVisibleRange()
            logger.info('[Time Range]', timeRange)
            const currentPrice = symbol.tradePrice
            logger.info('[Current Price]', currentPrice)

            const points = [
                { time: timeRange.from, price: currentPrice },
                { time: timeRange.to, price: priceRange.to }
            ]

            return activeChart.createMultipointShape(
                points,
                {
                    shape: 'rectangle',
                    overrides: {
                        backgroundColor: '#00a79e',
                        color: 'rgba(0, 167, 158, 0.1)',
                        transparency: 90,
                        lineWidth: 0
                    }
                }
            )
        } catch (e) {
            logger.error('[Draw Trade Rise Rectangle Shape Error]', e)
        }
    }

    const deleteTradeRiseRectangleShape = () => {
        if (!tradeRiseRectangleShapeId) return
        logger.debug('[Delete Trade Rise Rectangle Shape]', tradeRiseRectangleShapeId)
        setTradeRiseRectangleShapeId(null)
        tvWidget.activeChart().removeEntity(tradeRiseRectangleShapeId)
    }

    const drawTradeFallRectangleShape = () => {
        try {
            logger.debug('[Draw Trade Fall Rectangle Shape]')
            const activeChart = tvWidget.activeChart()
            const panes = activeChart.getPanes()
            const pane = panes?.find((pane) => pane.hasMainSeries())
            const rightScales = pane?.getRightPriceScales()
            const rightScale = rightScales?.find((scale) => scale.hasMainSeries())

            if (!rightScale) return

            const priceRange = rightScale?.getVisiblePriceRange()
            logger.info('[Price Range]', priceRange)
            const timeRange = activeChart.getVisibleRange()
            logger.info('[Time Range]', timeRange)
            const currentPrice = symbol.tradePrice
            logger.info('[Current Price]', currentPrice)

            const points = [
                { time: timeRange.from, price: priceRange.from },
                { time: timeRange.to, price: currentPrice }
            ]

            return activeChart.createMultipointShape(
                points,
                {
                    shape: 'rectangle',
                    overrides: {
                        backgroundColor: '#ff444f',
                        color: 'rgba(255, 68, 79, 0.1)',
                        transparency: 90,
                        lineWidth: 0
                    }
                }
            )
        } catch (e) {
            logger.error('[Draw Trade Fall Rectangle Shape Error]', e)
        }
    }

    const deleteTradeFallRectangleShape = () => {
        if (!tradeFallRectangleShapeId) return
        logger.debug('[Delete Trade Fall Rectangle Shape]', tradeFallRectangleShapeId)
        setTradeFallRectangleShapeId(null)
        tvWidget.activeChart().removeEntity(tradeFallRectangleShapeId)
    }

    return (
        <div className={cn(styles.dashboardChartContainer, props.className)} style={props.style}>
            {loading &&
            <div className='flex flex-col w-full h-full justify-center content-center place-content-center'>
                <Spin size='large' />
            </div>}

            {!loading && toShowNoMarketAlert &&
            <div className='flex flex-col w-full h-full justify-center content-center place-content-center'>
                <FontAwesomeIcon icon={faBoxArchive} className='text-gray-600 mb-3' size='2xl' />
                <p className='text-center text-lg text-gray-600'>There is no available market currently</p>
            </div>}

            <div
                ref={refChartContainer}
                id='dashboard-chart'
                className={styles.chart}
                style={{
                    display: (!loading && !toShowNoMarketAlert) ? 'block' : 'none'
                }}
            />
        </div>
    )
}

DashboardChart.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

DashboardChart.defaultProps = {
}

export default inject('userStore', 'symbolListStore', 'recentTradeListStore')(observer(DashboardChart))
