import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Form } from 'antd'
import styles from './SymbolFieldset.module.less'
import TradeContext from '@contexts/trade/TradeContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const SymbolFieldset = (props) => {
    const { symbol, setToShowSelectSymbolModal } = useContext(TradeContext)

    useEffect(() => {
    }, [])

    const handleBtnChangeSymbolClick = () => {
        setToShowSelectSymbolModal(true)
    }

    return (
        <fieldset className={cn(props.className, styles.symbolFieldset)} style={props.style}>
            <Form.Item
                className='mb-0'
                label='Symbol'
            >
                <button type='button' className={styles.btnChangeSymbol} onClick={handleBtnChangeSymbolClick}>
                    {symbol.id?.toUpperCase()}
                    <FontAwesomeIcon icon={faCaretDown} className='ml-3' size='lg' />
                </button>
            </Form.Item>
        </fieldset>
    )
}

SymbolFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

SymbolFieldset.defaultProps = {
}

export default SymbolFieldset
