import { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import moment from 'moment'
import styles from './TradeOptionPauseAlert.module.less'

const TradeOptionPauseAlert = (props) => {
    return (
        <div className={cn(props.className, styles.tradeOptionPauseAlert)} style={props.style}>
            <b className='text-md'>This market is closed for trading until further notice</b>
        </div>
    )
}

TradeOptionPauseAlert.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    symbol: PropTypes.object
}

TradeOptionPauseAlert.defaultProps = {
}

export default TradeOptionPauseAlert
