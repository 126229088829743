import React, { useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import RequestResetPasswordForm from '../RequestResetPasswordForm'
import styles from './RequestResetPasswordFormWrapper.module.less'

const RequestResetPasswordFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1>Request Reset Password</h1>
            <p>We'll email you instructions to reset your password.</p>

            <RequestResetPasswordForm
                className='mt-5 mb-3 w-full'
                onSuccessSubmit={props.onSuccessSubmit}
            />

            <Link to='/login'>
                Return Login
            </Link>
        </div>
    )
}

RequestResetPasswordFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

RequestResetPasswordFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default RequestResetPasswordFormWrapper
