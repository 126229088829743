import Layout from '@components/user/auth/layout/AuthLayout'
import RequestResetPasswordFormWrapper from '@components/user/auth/request-reset-password/form/wrapper/RequestResetPasswordFormWrapper'

const RequestResetPasswordPage = () => {
    return (
        <Layout>
            <RequestResetPasswordFormWrapper />
        </Layout>
    )
}

export default RequestResetPasswordPage
