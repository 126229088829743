import moment from 'moment'
import getTimeframeFromResolution from '@helpers/datafeed/getTimeframeFromResolution'

const getNextBarTime = (barTime, resolution) => {
    const timeframe = getTimeframeFromResolution(resolution)
    const [durationAmount, durationInput] = timeframe.match(/\D+|\d+/g)
    const nextBarTime = moment(barTime).add(durationAmount, durationInput).unix()
    return nextBarTime * 1000
}

export default getNextBarTime
