import { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Input } from 'antd'
import { inject } from 'mobx-react'
import focusErrorInput from '@helpers/focusErrorInput'
import FormError from '@components/common/form/error/FormError'
import FormSuccess from '@components/common/form/success/FormSuccess'
import schema from './schema'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import container from '@container'

const LoginForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const [successMessage, setSuccessMessage] = useState(props.successMessage)
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
    }, [])

    useEffect(() => {
        setSuccessMessage(props.successMessage)
    }, [props.successMessage])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            setSuccessMessage(null)

            const res = await props.userStore.login(
                data.email,
                data.password
            )

            if (res.ok) {
                const data = res.data
                if (data.auth) {
                    reset()
                    if (props.onSuccessSubmit) props.onSuccessSubmit(data)
                } else {
                    setApiErrorMessages(['Invalid email or password'])
                }
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            logger.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            {successMessage &&
            <FormSuccess className='mb-3' message={successMessage} />}

            <Form.Item
                className='mb-4'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            name='email'
                            className='input-text-full'
                            placeholder='Email'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Password'
                validateStatus={(errors?.password?.message) ? 'error' : '-'}
                help={errors?.password?.message}
            >
                <Controller
                    name='password'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            type='password'
                            name='password'
                            className='input-text-full'
                            placeholder='Password'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <div className='mb-2'>
                <Link to='/request-reset-password'>
                    Forgot password?
                </Link>
            </div>

            <SubmitButton className='mt-1' loading={submitting}>
                Submit
            </SubmitButton>
        </Form>
    )
}

LoginForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    onSuccessSubmit: PropTypes.func
}

LoginForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('userStore')(LoginForm)
