import { useState, useEffect } from 'react'
import { Modal, Tabs } from 'antd'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './SettingModal.module.less'
import ThemeTabPane from '@components/setting/theme/tab-pane/ThemeTabPane'

const SettingModal = (props) => {
    return (
        <Modal
            open={props.isOpened}
            title={<>Settings</>}
            footer={null}
            className={cn(props.className, styles.wrapper)}
            onCancel={props.onCancel}
        >
            <Tabs
                defaultActiveKey='1'
                tabPosition='left'
                className={styles.tabs}
                items={[
                    {
                        label: 'Themes',
                        key: 'themes',
                        children: <ThemeTabPane />
                    }
                ]}
            />
        </Modal>
    )
}

SettingModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    isOpened: PropTypes.bool
}

SettingModal.defaultProps = {
    isOpened: false
}

export default SettingModal
