import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Link, useNavigate } from 'react-router-dom'
import { inject, observer } from 'mobx-react'
import container from '@container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import styles from './UserAccountDropdownMenu.module.less'

const UserAccountDropdownMenu = (props) => {
    const navigate = useNavigate()

    const logout = () => {
        const apiWebsocketClient = container.get('apiWebsocketClient')
        apiWebsocketClient.close()
        props.userStore.logout()
        navigate('/login')
    }

    const navigateToAccountSettingPage = () => {
    }

    const navigateToTradeListPage = () => {
        navigate({
            pathname: '/trades'
        })
    }

    const navigateToDashboard = () => {
        navigate({
            pathname: '/dashboard'
        })
    }

    return (
        <ul className={cn(props.className, styles.userAccountDropdownMenu)} style={props.style}>
            <li onClick={navigateToDashboard}>
                Dashboard
            </li>
            <li onClick={navigateToAccountSettingPage}>
                Account Settings
            </li>
            <li onClick={navigateToTradeListPage}>
                Trade History
            </li>
            <li onClick={logout}>
                Log out
                <FontAwesomeIcon icon={faRightFromBracket} className='ml-2' />
            </li>
        </ul>
    )
}

UserAccountDropdownMenu.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

UserAccountDropdownMenu.defaultProps = {
}

export default inject('userStore')(observer(UserAccountDropdownMenu))
