import cn from 'classnames'
import PropTypes from 'prop-types'
import styles from './FormSuccess.module.less'

const FormSuccess = props => {
    return (
        <>
            {props.message &&
            <div className={cn(props.className, styles.formSuccessWrapper)}>
                {props.message}
            </div>}
        </>
    )
}

FormSuccess.propTypes = {
    className: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

FormSuccess.defaultProps = {
}

export default FormSuccess
