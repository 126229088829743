import { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'

const UserPublicRoute = ({ children, userStore, restricted, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(userStore.accessToken && userStore.isLogined)

    useEffect(() => {
        setIsAuthed(userStore.accessToken && userStore.isLogined)
    }, [userStore.accessToken, userStore.isLogined])

    if (isAuthed && restricted) {
        return <Navigate to={restProps.redirectTo} replace />
    }

    return children
}

UserPublicRoute.propTypes = {
    restricted: PropTypes.bool,
    redirectTo: PropTypes.object
}

UserPublicRoute.defaultProps = {
    restricted: false,
    redirectTo: {
        pathname: '/dashboard'
    }
}

export default inject('userStore')(observer(UserPublicRoute))
