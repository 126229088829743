import React, { useEffect, useMemo, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import _ from 'lodash'
import moment from 'moment'
import { Popover } from 'antd'
import { ClockCircleOutlined } from '@ant-design/icons'
import styles from './TimeInput.module.less'

const TimeInput = React.forwardRef((props, ref) => {
    const inputRef = useRef()
    const [isPopoverOpened, setIsPopoverOpened] = useState(false)
    const [isMounted, setIsMounted] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const [selectedHour, setSelectedHour] = useState(null)
    const [selectedMinute, setSelectedMinute] = useState(null)
    const [selectedSecond, setSelectedSecond] = useState(null)
    const [value, setValue] = useState(null)

    const hourOptions = useMemo(() => {
        const numOfHourOption = 24 / props.hourInterval
        const hours = _.times(numOfHourOption, (i) => {
            return i * props.hourInterval
        })
        return hours
    }, [props.hourInterval])

    const minuteOptions = useMemo(() => {
        const numOfMinuteOption = 60 / props.minuteInterval
        const minutes = _.times(numOfMinuteOption, (i) => {
            return i * props.minuteInterval
        })
        return minutes
    }, [props.minuteInterval])

    useEffect(() => {
        setIsMounted(true)
    }, [])

    useEffect(() => {
        if (!isMounted) return
        const hour = selectedHour ? (selectedHour < 10) ? `0${selectedHour}` : selectedHour.toString() : '00'
        const minute = selectedMinute ? (selectedMinute < 10) ? `0${selectedMinute}` : selectedMinute.toString() : '00'
        const second = selectedSecond ? (selectedSecond < 10) ? `0${selectedSecond}` : selectedSecond.toString() : '00'
        const selectedTime = moment(`${hour}:${minute}:${second}`, 'HH:mm:ss')
        props.onChange(selectedTime)
        setValue(selectedTime.format('HH:mm:ss'))
    }, [selectedHour, selectedMinute, selectedSecond])

    const handleTimeInputWrapperClick = (e) => {
        inputRef?.current?.focus()
    }

    const handleInputFocus = () => {
        setIsFocused(true)
    }

    const handlePopoverVisibleChange = (visible) => {
        if (!visible) setIsFocused(false)
    }

    const handleHourClick = (hour) => {
        let minute = (selectedMinute) ?? findFirstAvailableMinute(hour)
        const disabledMinutes = props.disabledMinutes(hour)
        const isDisabled = disabledMinutes.some((disabledMinute) => {
            return disabledMinute >= minute
        })
        if (isDisabled) {
            minute = findFirstAvailableMinute(hour)
        }
        setSelectedHour(hour)
        setSelectedMinute(minute)
    }

    const handleMinuteClick = (minute) => {
        const hour = (selectedHour) ?? findFirstAvailableHour()
        const disabledMinutes = props.disabledMinutes(hour)
        const isDisabled = disabledMinutes.some((disabledMinute) => {
            return disabledMinute >= minute
        })
        if (isDisabled) {
            minute = findFirstAvailableMinute(hour)
        }
        setSelectedHour(hour)
        setSelectedMinute(minute)
    }

    const findFirstAvailableHour = () => {
        const disabledHours = props.disabledHours()
        const hour = _.find(hourOptions, (hour) => {
            const notDisabled = !disabledHours.includes(hour)
            return notDisabled
        })
        return hour
    }

    const findFirstAvailableMinute = (hour) => {
        const disabledMinutes = props.disabledMinutes(hour)
        const minute = _.find(minuteOptions, (minute) => {
            const notDisabled = !disabledMinutes.includes(minute)
            return notDisabled
        })
        return minute
    }

    return (
        <Popover
            placement='leftTop'
            overlayClassName={styles.timePickerPopover}
            // open={isPopoverOpened}
            trigger='click'
            onOpenChange={handlePopoverVisibleChange}
            content={
                <div className={styles.timePickerContainer}>
                    <div className={styles.hourContainer}>
                        <div className={styles.title}>
                            Hour
                        </div>

                        <ul>
                            {hourOptions.map((hour, index) => {
                                const disabledHours = props.disabledHours()
                                const isDisabled = disabledHours.some((disabledHour) => {
                                    return disabledHour >= hour
                                })
                                const hourFormatted = (hour < 10) ? `0${hour}` : hour.toString()
                                return (
                                    <li
                                        key={index}
                                        className={cn({ [styles.disabled]: isDisabled, [styles.active]: hour === selectedHour })}
                                        onClick={() => handleHourClick(hour)}
                                    >
                                        {hourFormatted}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className={styles.minuteContainer}>
                        <div className={styles.title}>
                            Minute
                        </div>

                        <ul>
                            {minuteOptions.map((minute, index) => {
                                const disabledMinutes = props.disabledMinutes(selectedHour)
                                const isDisabled = disabledMinutes.some((disabledMinute) => {
                                    return disabledMinute >= minute
                                })
                                const minuteFormatted = (minute < 10) ? `0${minute}` : minute.toString()
                                return (
                                    <li
                                        key={index}
                                        className={cn({ [styles.disabled]: isDisabled, [styles.active]: minute === selectedMinute })}
                                        onClick={() => handleMinuteClick(minute)}
                                    >
                                        {minuteFormatted}
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            }
        >
            <div className={cn(styles.timeInputWrapper, { [styles.focused]: isFocused }, props.className)} onClick={handleTimeInputWrapperClick}>
                <input
                    className={styles.timeInput}
                    style={props.style}
                    type='text'
                    ref={inputRef}
                    value={(value) ?? ''}
                    onChange={() => {}}
                    placeholder={props.placeholder}
                    onFocus={handleInputFocus}
                />

                <ClockCircleOutlined className={styles.suffix} />
            </div>
        </Popover>
    )
})

TimeInput.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    placeholder: PropTypes.string,
    disabledHours: PropTypes.func,
    disabledMinutes: PropTypes.func,
    disabledSeconds: PropTypes.func,
    hourInterval: PropTypes.number,
    minuteInterval: PropTypes.number,
    onChange: PropTypes.func
}

TimeInput.defaultProps = {
    placeholder: 'Select time',
    disabledHours: () => {},
    disabledMinutes: () => {},
    disabledSeconds: () => {},
    hourInterval: 1,
    minuteInterval: 1,
    onChange: () => {}
}

export default TimeInput
