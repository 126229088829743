import { useContext, useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { inject } from 'mobx-react'
import styles from './DashboardSidebar.module.less'
import TradeOptionContractForm from '@components/user/option-contract/trade/form/TradeOptionContractForm'
import { Button, Spin } from 'antd'
import TradeContext from '@contexts/trade/TradeContext'
import TradeOptionPauseAlert from '@components/user/option-contract/trade/pause/alert/TradeOptionPauseAlert'
import container from '@container'

const DashboardSidebar = (props) => {
    const { symbol, setToShowRecentTrade, setToReloadRecentTradeList } = useContext(TradeContext)
    const [loading, setLoading] = useState(true)
    const [toShowTradePauseAlert, setToShowTradePauseAlert] = useState(false)
    const [toShowNoMarketAlert, setToShowNoMarketAlert] = useState(false)

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        try {
            setLoading(true)
            const selectedSymbol = props.symbolListStore.symbols.get(symbol?.id)
            logger.info('[Symbol Is Trading Pause]', selectedSymbol?.isTradingPause)
            if (selectedSymbol) {
                setToShowTradePauseAlert(selectedSymbol.isTradingPause)
            }
            setToShowNoMarketAlert(false)
        } catch (e) {
            logger.error('[Failed to check is there any market is paused]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }, [props.symbolListStore.symbols.get(symbol?.id)])

    useEffect(() => {
        try {
            logger.info('[Symbol List Size Changed]', props.symbolListStore.symbols.size)
            setLoading(true)
            setToShowNoMarketAlert(props.symbolListStore.symbols.size === 0)
        } catch (e) {
            logger.error('[Failed to check is there any avaialble market]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }, [props.symbolListStore.symbols.size])

    const handleBtnShowRecentTradeClick = () => {
        setToShowRecentTrade(true)
    }

    const handleSuccessTrade = () => {
        setToReloadRecentTradeList(true)
    }

    return (
        <div className={cn(styles.sidebar)}>
            {!loading &&
            <>
                {toShowNoMarketAlert &&
                <div>
                    <p className='text-center mb-2 font-bold'>There is no available market currently</p>
                </div>}

                {!toShowNoMarketAlert &&
                <>
                    {!toShowTradePauseAlert &&
                    <TradeOptionContractForm className='mb-2' onSuccessSubmit={handleSuccessTrade} />}

                    {toShowTradePauseAlert &&
                    <TradeOptionPauseAlert className='mb-2' symbol={props.symbolListStore.symbols.get(symbol.id)} />}
                </>}
            </>}

            {loading &&
            <div className='text-center flex flex-col p-3 pt-5 text-gray-400'>
                <Spin className='mb-2' />
                Loading...
            </div>}

            <Button className={styles.btnShowRecentTrade} onClick={handleBtnShowRecentTradeClick}>
                Show Recent Trades
            </Button>
        </div>
    )
}

DashboardSidebar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

DashboardSidebar.defaultProps = {
}

export default inject('symbolListStore')(DashboardSidebar)
