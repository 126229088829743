import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './RecentTradeList.module.less'
import RecentTradeListItem from './item/RecentTradeListItem'

const RecentTradeList = (props) => {
    return (
        <ul className={cn(props.className, styles.recentTradeList)} style={props.style}>
            {props.trades.map((trade, index) => {
                return (
                    <RecentTradeListItem key={index} index={index} {...trade} />
                )
            })}

            {props.trades.length === 0 &&
            <p className='text-center p-3 text-gray-400'>You have no active trades now</p>}
        </ul>
    )
}

RecentTradeList.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    trades: PropTypes.array,
    loading: PropTypes.bool
}

RecentTradeList.defaultProps = {
    trades: [],
    loading: true
}

export default RecentTradeList
