import { useEffect, useMemo, useState } from 'react'
import { inject } from 'mobx-react'
import { Tabs } from 'antd'
import qs from 'qs'
import { useLocation, useNavigate, createSearchParams } from 'react-router-dom'
import Layout from '@components/user/layout/Layout'
import container from '@container'
import TradeList from '@components/user/option-contract/trade/list/TradeList'
import processPagination from '@helpers/processPagination'

const UserTradeListPage = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })
    const [activeTabKey, setActiveTabKey] = useState('all')
    const [loading, setLoading] = useState(true)
    const [trades, setTrades] = useState([])
    const [pagination, setPagination] = useState({
        current: 1
    })

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        const query = qs.parse(location.search, { ignoreQueryPrefix: true })
        const load = async () => {
            if (query.status === 'all' || !query.status) {
                setActiveTabKey('all')
                await getTradeList(query)
            } else if (query.status === 'active') {
                setActiveTabKey(query.status)
                await getActiveTradeList(query)
            } else if (query.status === 'completed') {
                setActiveTabKey(query.status)
                await getCompletedTradeList(query)
            }
        }
        load()
    }, [location.search])

    const getTradeList = async (query) => {
        try {
            setLoading(true)
            const tradeService = container.get('tradeService')
            const res = await tradeService.getAll(props.userStore.accessToken, query)
            if (res.ok) {
                const data = res.data
                setTrades(data.data)
                setPagination(processPagination(data))
            }
        } catch (e) {
            logger.error('[Failed to get trades]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }

    const getActiveTradeList = async (query) => {
        try {
            setLoading(true)
            const tradeService = container.get('tradeService')
            const res = await tradeService.getActiveList(props.userStore.accessToken, query)
            if (res.ok) {
                const data = res.data
                setTrades(data.data)
                setPagination(processPagination(data))
            }
        } catch (e) {
            logger.error('[Failed to get active trades]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }

    const getCompletedTradeList = async (query) => {
        try {
            setLoading(true)
            const tradeService = container.get('tradeService')
            const res = await tradeService.getCompletedList(props.userStore.accessToken, query)
            if (res.ok) {
                const data = res.data
                setTrades(data.data)
                setPagination(processPagination(data))
            }
        } catch (e) {
            logger.error('[Failed to get completed trades]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }

    const handleTabChange = (activeKey) => {
        const params = {
            status: activeKey
        }
        if (activeKey === 'all') delete params.status
        navigate({
            pathname: '/trades',
            search: createSearchParams(params).toString()
        })
    }

    const handlePaginationChange = (page, pageSize) => {
        navigate({
            pathname: '/trades',
            search: createSearchParams({
                ...query,
                page
            }).toString()
        })
    }

    const tabItems = [
        { label: 'All', key: 'all', children: <TradeList loading={loading} trades={trades} pagination={pagination} handlePaginationChange={handlePaginationChange} /> },
        { label: 'Active', key: 'active', children: <TradeList loading={loading} trades={trades} pagination={pagination} handlePaginationChange={handlePaginationChange} /> },
        { label: 'Completed', key: 'completed', children: <TradeList loading={loading} trades={trades} pagination={pagination} handlePaginationChange={handlePaginationChange} /> }
    ]

    return (
        <Layout>
            <div className='container mx-auto px-3 mb-10 pb-5'>
                <Tabs
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                    items={tabItems}
                />
            </div>
        </Layout>
    )
}

export default inject('userStore')(UserTradeListPage)
