import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import numeral from 'numeral'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpWideShort } from '@fortawesome/free-solid-svg-icons'
import styles from './CallOptionButton.module.less'

const CallOptionButton = (props) => {
    return (
        <button
            type='button'
            disabled={props.submitting || props.disabled}
            className={cn(styles.btnCallOption, props.className)}
            style={props.style}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <FontAwesomeIcon icon={faArrowUpWideShort} className='mr-2' size='lg' />
            <span>Rise</span>

            {props.profit &&
            <span className={styles.profit}>{numeral(props.profit).format('0,0.00')}%</span>}
        </button>
    )
}

CallOptionButton.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    submitting: PropTypes.bool,
    disabled: PropTypes.bool,
    profit: PropTypes.number
}

CallOptionButton.defaultProps = {
    onClick: () => {},
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    submitting: false,
    disabled: false
}

export default CallOptionButton
