import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Provider as MobxProvider } from 'mobx-react'
import ThemeProvider from '@contexts/theme/ThemeProvider'
import AppContext from './AppContext'
import SettingModal from '@components/setting/modal/SettingModal'

const { Provider } = AppContext

const AppProvider = props => {
    const [isSettingModalVisible, setIsSettingModalVisible] = useState(false)
    const [toShowLayoutOverlay, setToShowLayoutOverlay] = useState(false)

    const container = useMemo(() => {
        return props.container
    }, [props.container])

    useEffect(() => {
    }, [])

    return (
        <Provider value={{ setIsSettingModalVisible, toShowLayoutOverlay, setToShowLayoutOverlay }}>
            <ThemeProvider>
                <MobxProvider
                    userStore={container.get('userStore')}
                    symbolListStore={container.get('symbolListStore')}
                    recentTradeListStore={container.get('recentTradeListStore')}
                >
                    {props.children}

                    <SettingModal
                        isOpened={isSettingModalVisible}
                        onCancel={() => setIsSettingModalVisible(false)}
                    />
                </MobxProvider>
            </ThemeProvider>
        </Provider>
    )
}

AppProvider.propTypes = {
}

AppProvider.defaultProps = {
}

export default AppProvider
