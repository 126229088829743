import React, { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import TradeContext from './TradeContext'
import container from '@container'
import SelectSymbolModal from '@components/user/option-contract/modal/SelectSymbolModal'

const { Provider } = TradeContext

const TradeProvider = props => {
    const [symbol, setSymbol] = useState({
        id: null,
        name: null,
        tradePrice: null,
        maxDecimalPoints: null
    })
    const [toShowRecentTrade, setToShowRecentTrade] = useState(window.innerWidth >= 768)
    const [toReloadRecentTradeList, setToReloadRecentTradeList] = useState(false)
    const [toShowSelectSymbolModal, setToShowSelectSymbolModal] = useState(false)
    const [isTradeRiseBtnHovered, setIsTradeRiseBtnHovered] = useState(false)
    const [isTradeFallBtnHovered, setIsTradeFallBtnHovered] = useState(false)

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        logger.info('[Selected Symbol]', symbol)
    }, [symbol])

    return (
        <Provider
            value={{
                symbol,
                setSymbol,
                toShowRecentTrade,
                setToShowRecentTrade,
                toReloadRecentTradeList,
                setToReloadRecentTradeList,
                toShowSelectSymbolModal,
                setToShowSelectSymbolModal,
                isTradeRiseBtnHovered,
                setIsTradeRiseBtnHovered,
                isTradeFallBtnHovered,
                setIsTradeFallBtnHovered
            }}
        >
            {props.children}

            <SelectSymbolModal
                visible={toShowSelectSymbolModal}
                onSymbolClick={() => {
                    setToShowSelectSymbolModal(false)
                }}
                onCancel={() => {
                    setToShowSelectSymbolModal(false)
                }}
            />
        </Provider>
    )
}

TradeProvider.propTypes = {
}

TradeProvider.defaultProps = {
}

export default TradeProvider
