import { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import numeral from 'numeral'
import { inject } from 'mobx-react'
import { Statistic, Progress } from 'antd'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpWideShort, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons'
import styles from '../RecentTradeList.module.less'
import TradeSide from '@models/enum/TradeSide'
import TradeStatus from '@models/enum/TradeStatus'

const { Countdown } = Statistic

const RecentTradeListItem = (props) => {
    const [durationPercentageLeft, setDurationPercentageLeft] = useState(100)

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    }, [])

    const priceFormat = useMemo(() => {
        const symbol = props.symbolListStore.symbols.get(props.symbol)
        if (!symbol) {
            return '$0,0.00'
        }
        return `$0,0.${''.padStart(symbol.maxDecimalPoints, '0')}`
    }, [props.symbolListStore.symbols.get(props.symbol)])

    const tick = () => {
        const now = moment()
        const end = moment(props.tradeEndAt)
        const duration = moment.duration(end.diff(now))
        const minutes = duration.asMinutes()
        setDurationPercentageLeft((minutes / props.duration) * 100)
    }

    return (
        <div className={cn(props.className, styles.item)} style={props.style}>
            <div className={styles.header}>
                <span className={styles.symbol}>
                    {props.symbol}
                </span>

                {props.side === TradeSide.RISE.enumKey &&
                <div className={styles.sideRise}>
                    <FontAwesomeIcon icon={faArrowUpWideShort} className='mr-1' size='lg' />

                    {TradeSide.RISE.label}
                </div>}

                {props.side === TradeSide.FALL.enumKey &&
                <div className={styles.sideFall}>
                    <FontAwesomeIcon icon={faArrowDownWideShort} className='mr-1' size='lg' />

                    {TradeSide.FALL.label}
                </div>}
            </div>

            <div className={styles.body}>
                {props.status === TradeStatus.ACTIVE.enumKey &&
                <div className={styles.countdownWrapper}>
                    <Countdown
                        value={moment(props.tradeEndAt)}
                        onFinish={() => {}}
                    />

                    <Progress
                        percent={durationPercentageLeft}
                        status='normal'
                        showInfo={false}
                    />
                </div>}
                <div className={styles.infoWrapper}>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            Amount
                        </div>
                        <div className={styles.value}>
                            {numeral(props.amount).format('$0,0.00')}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            Price
                        </div>
                        <div className={styles.value}>
                            {numeral(props.price).format(priceFormat)}
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            Profit Percent
                        </div>
                        <div className={styles.value}>
                            {numeral(props.profitPercent).format('0.00')}%
                        </div>
                    </div>
                    <div className={styles.info}>
                        <div className={styles.title}>
                            Status
                        </div>
                        <div className={styles.value}>
                            <span className={cn(styles.status, styles[TradeStatus.of(props.status)?.enumKey])}>
                                {TradeStatus.of(props.status)?.label}
                            </span>
                        </div>
                    </div>
                    <div className={cn(styles.info, 'col-span-2')}>
                        <div className={styles.title}>
                            Nett Profit
                        </div>
                        <div className={styles.value}>
                            {(props.nettProfit) ? numeral(props.nettProfit).format('$0,0.00') : '-'}
                        </div>
                    </div>
                    <div className={cn(styles.info, 'col-span-2')}>
                        <div className={styles.title}>
                            Open At
                        </div>
                        <div className={cn(styles.value, 'text-gray-400')}>
                            {moment.utc(props.tradeOpenAt).local().format('D MMM YYYY, hh:mm:ss A')}
                        </div>
                    </div>
                    <div className={cn(styles.info, 'col-span-2')}>
                        <div className={styles.title}>
                            End At
                        </div>
                        <div className={cn(styles.value, 'text-gray-400')}>
                            {moment.utc(props.tradeEndAt).local().format('D MMM YYYY, hh:mm:ss A')}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

RecentTradeListItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

RecentTradeListItem.defaultProps = {
}

export default inject('symbolListStore')(RecentTradeListItem)
