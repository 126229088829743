import EventBus from '@vertx/eventbus-bridge-client.js'
import logger from '@utils/logger'
class Socket {
    eb = null
    url = null
    options = null
    authToken = null
    userId = null
    callbacks = new Map()
    mockCompletedTrades = []

    constructor (url, options = {}) {
        this.url = url
        this.options = options
    }

    initEb () {
        if (!this.eb) {
            this.eb = new EventBus(this.url, this.options)
        }
    }

    setAuthToken = (authToken) => {
        this.authToken = authToken
    }

    setUserId = (userId) => {
        this.userId = userId
    }

    setupListener = () => {
        this.initEb()

        this.eb.onopen = () => {
            logger.info('[Socket] Connected')
            const authHeader = {
                Authorization: 'Bearer ' + this.authToken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }

            /* eslint-disable node/handle-callback-err */
            this.eb.registerHandler('binavictory.service.api.pub', authHeader, (err, msg) => {
                for (const [key, value] of this.callbacks) {
                    const callback = this.callbacks.get(key)
                    if (callback.address === 'binavictory.service.api.pub') {
                        callback.func(msg)
                    }
                }
            })

            this.eb.registerHandler(`binavictory.service.api.tradeEvent.${this.userId}`, authHeader, (err, msg) => {
                logger.info('[Receive Trade Event]')
                for (const [key, value] of this.callbacks) {
                    const callback = this.callbacks.get(key)
                    if (callback.address === `binavictory.service.api.tradeEvent.${this.userId}`) {
                        callback.func(msg)
                    }
                }
            })
        }

        this.eb.onclose = () => {
            logger.info('[Socket] Closed')
            this.eb = null
        }
    }

    // TODO: Remove/Comment
    addMockCompletedTrades = (trade) => {
        logger.info('[Add Mock Completed Trade]', trade)
        this.mockCompletedTrades.push(trade)
    }

    addCallback = (name, address, callbackFunc) => {
        this.callbacks.set(name, {
            address,
            func: callbackFunc
        })
    }

    removeCallbacks = (callbackNames = []) => {
        callbackNames.forEach((name) => {
            this.callbacks.delete(name)
        })
    }

    close = () => {
        this.authToken = null
        this.userId = null
        this.callbacks = new Map()
        this.eb?.close()
    }
}

export default Socket
