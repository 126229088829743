import { types, flow, getEnv } from 'mobx-state-tree'
import moment from 'moment'

const DecimalPrimitive = types.custom({
    name: 'Decimal',
    fromSnapshot (value) {
        return parseFloat(value)
    },
    toSnapshot (value) {
        return value.toString()
    },
    isTargetType (value) {
        return parseFloat(value)
    },
    getValidationMessage (value) {
        if (/^-?\d+\.\d+$/.test(value)) return '' // OK
        if (/^\d+$/.test(value)) return '' // OK
        return `'${value}' doesn't look like a valid decimal number`
    }
})

export const SymbolStore = types
    .model('SymbolStore', {
        id: types.maybeNull(types.string),
        name: types.maybeNull(types.string),
        type: types.maybeNull(types.string),
        maxDecimalPoints: types.maybeNull(DecimalPrimitive, 0),
        minAmount: types.maybeNull(DecimalPrimitive, 0),
        maxAmount: types.maybeNull(DecimalPrimitive, 0),
        tradingPause: types.optional(types.boolean, false),
        profitPercentRise: types.maybeNull(DecimalPrimitive, 0),
        profitPercentFall: types.maybeNull(DecimalPrimitive, 0),
        potentialProfit: types.maybeNull(DecimalPrimitive, 0),
        tradingRestStartAt: types.maybeNull(types.Date),
        tradingRestEndAt: types.maybeNull(types.Date),
        dailyRestStartAt: types.maybeNull(types.Date),
        dailyRestEndAt: types.maybeNull(types.Date)
    })
    .views((self) => {
        return {
            get isTradingPause () {
                if (self.tradingPause) {
                    return true
                }

                const now = moment()

                if (self.tradingRestStartAt && self.tradingRestEndAt) {
                    if (now.isSameOrAfter(moment(self.tradingRestStartAt)) && now.isBefore(moment(self.tradingRestEndAt))) {
                        return true
                    }
                }

                if (self.dailyRestStartAt && self.dailyRestEndAt) {
                    if (now.isSameOrAfter(moment(self.dailyRestStartAt)) && now.isBefore(moment(self.dailyRestEndAt))) {
                        return true
                    }
                }

                return false
            },
            get reopenAt () {
                const now = moment()

                if (self.tradingRestStartAt && self.tradingRestEndAt) {
                    if (now.isSameOrAfter(moment(self.tradingRestStartAt)) && now.isBefore(moment(self.tradingRestEndAt))) {
                        return self.tradingRestEndAt
                    }
                }

                if (self.dailyRestStartAt && self.dailyRestEndAt) {
                    if (now.isSameOrAfter(moment(self.dailyRestStartAt)) && now.isBefore(moment(self.dailyRestEndAt))) {
                        return self.dailyRestEndAt
                    }
                }

                return now
            }
        }
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        }
    }))
