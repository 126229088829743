class WebClientResult {
    ok = false
    data = null
    status = 200

    constructor (ok, data = null, status) {
        this.ok = ok
        this.data = data
        this.status = status
    }
}

export default WebClientResult
