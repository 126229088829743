import { useState, useEffect, useRef, useLayoutEffect, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { inject, observer } from 'mobx-react'
import { Layout, Spin } from 'antd'
import SimpleBar from 'simplebar-react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import styles from './RecentTradeListSider.module.less'
import RecentTradeList from '../RecentTradeList'
import TradeContext from '@contexts/trade/TradeContext'
import PrimaryButton from '@components/common/button/primary/PrimaryButton'
import container from '@container'
import AppContext from '@contexts/app/AppContext'

const { Sider } = Layout

const RecentTradeListSider = (props) => {
    const refSide = useRef(null)
    const refHeader = useRef(null)
    const navigate = useNavigate()
    const { setToShowLayoutOverlay } = useContext(AppContext)
    const { toShowRecentTrade, setToShowRecentTrade, toReloadRecentTradeList, setToReloadRecentTradeList } = useContext(TradeContext)
    const [loading, setLoading] = useState(true)

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        const load = async () => {
            await getRecentList()
        }
        load()
    }, [])

    useEffect(() => {
        const handleResize = () => {
            setToShowRecentTrade((window.innerWidth >= 768))
        }

        window.addEventListener('resize', handleResize)
        handleResize()
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        setToShowLayoutOverlay(toShowRecentTrade && (window.innerWidth < 768))
    }, [toShowRecentTrade])

    useEffect(() => {
        const load = async () => {
            if (toReloadRecentTradeList) {
                await getRecentList()
                setToReloadRecentTradeList(false)
            }
        }
        load()
    }, [toReloadRecentTradeList])

    const getRecentList = async () => {
        try {
            setLoading(true)
            await props.recentTradeListStore.load(props.userStore.accessToken)
        } catch (e) {
            logger.error('[Failed to get recent trades]', e)
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }
    }

    const handleBtnCollapseClick = () => {
        setToShowRecentTrade(false)
    }

    const handleBtnSeeAllClick = () => {
        setToShowLayoutOverlay(false)
        navigate({
            pathname: '/trades'
        })
    }

    return (
        <Sider
            ref={refSide}
            className={cn(props.className, styles.recentTradeListSider)}
            style={props.style}
            collapsible
            collapsed={!toShowRecentTrade}
            collapsedWidth={0}
            onCollapse={value => setToShowRecentTrade(!value)}
            trigger={null}
        >
            <div className={styles.header} ref={refHeader}>
                <div className={styles.title}>Recent Trades</div>

                <button type='button' className={styles.btnCollapse} onClick={handleBtnCollapseClick}>
                    <FontAwesomeIcon icon={faMinus} />
                </button>
            </div>

            <SimpleBar
                className={styles.body}
                forceVisible
                autoHide={false}
            >
                {loading &&
                <div className='text-center flex flex-col p-3 pt-5 text-gray-400'>
                    <Spin className='mb-2' />
                    Loading...
                </div>}

                {!loading &&
                <RecentTradeList
                    trades={Array.from(props.recentTradeListStore.trades.values())}
                />}
            </SimpleBar>

            <div className={styles.footer}>
                <PrimaryButton className='w-full' onClick={handleBtnSeeAllClick}>
                    See All
                </PrimaryButton>
            </div>
        </Sider>
    )
}

RecentTradeListSider.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

RecentTradeListSider.defaultProps = {
}

export default inject('userStore', 'recentTradeListStore')(observer(RecentTradeListSider))
