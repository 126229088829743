import { useState, useEffect, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Spin, Modal, Input } from 'antd'
import SimpleBar from 'simplebar-react'
import styles from './SelectSymbolModal.module.less'
import container from '@container'
import TradeContext from '@contexts/trade/TradeContext'

const SelectSymbolModal = (props) => {
    const { symbol, setSymbol } = useContext(TradeContext)
    const [searchText, setSearchText] = useState('')
    const [symbols, setSymbols] = useState([])
    const [filteredSymbols, setFilteredSymbols] = useState([])
    const [loading, setLoading] = useState(true)

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    const config = useMemo(() => {
        return container.get('config')
    }, [])

    useEffect(() => {
        const load = async () => {
            getSymbolList()
        }
        load()
    }, [])

    useEffect(() => {
        if (searchText) {
            logger.info('[Search Symbol Text]', searchText)
            const filteredSymbols = symbols.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchText.toLowerCase())
            })
            logger.info('[Filtered Symbols]', filteredSymbols)
            setFilteredSymbols(filteredSymbols)
        }
    }, [searchText])

    const getSymbolList = async () => {
        try {
            setLoading(true)
            const symbolService = container.get('symbolService')
            const res = await symbolService.getAll()

            if (res.ok && res.data.config) {
                const symbols = res.data.config
                setSymbols(symbols)
                return symbols
            }
            return []
        } catch (e) {
            logger.error('Failed to get all symbols', e)
            return []
        } finally {
            setTimeout(() => {
                setLoading(false)
            }, 1500)
        }
    }

    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value)
    }

    const handleSymbolClick = (item, index) => {
        logger.info('[Click Symbol]', item)
        setSymbol({
            ...symbol,
            id: item.symbol,
            name: item.name,
            maxDecimalPoints: item.maxDecimalPoints
        })
        props.onSymbolClick()
    }

    const symbolsToRender = (searchText.length > 0) ? filteredSymbols : symbols

    return (
        <Modal
            className={cn(props.className, styles.selectSymbolModal)}
            title='Symbol Search'
            open={props.visible}
            onCancel={props.onCancel}
            footer={null}
        >
            <div className={styles.filterContainer}>
                <Input placeholder='Search by symbol name' value={searchText} onChange={handleSearchInputChange} />
            </div>

            <SimpleBar
                className={styles.symbolListWrapper}
                forceVisible
                autoHide={false}
            >
                {loading &&
                <div className='text-center flex flex-col p-3 pt-5 text-gray-400'>
                    <Spin className='mb-2' />
                    Loading...
                </div>}

                {!loading &&
                <ul className={styles.symbolList}>
                    {symbolsToRender.map((item, index) => {
                        const isActive = item.symbol === symbol.id
                        return (
                            <li key={index} className={cn({ [styles.active]: isActive })} onClick={() => handleSymbolClick(item, index)}>
                                <div className={styles.name}>{item.name}</div>
                                <div className={styles.description}>{item.name}</div>
                                <div className={styles.type}>{item.type}</div>
                                <div className={styles.exchange}>{config.app.appName}</div>
                            </li>
                        )
                    })}

                    {symbols.length === 0 &&
                    <p className='text-center m-auto text-gray-400'>No symbols match your criteria</p>}
                </ul>}
            </SimpleBar>
        </Modal>
    )
}

SelectSymbolModal.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onSymbolClick: PropTypes.func
}

SelectSymbolModal.defaultProps = {
    visible: false,
    onCancel: () => {},
    onSymbolClick: () => {}
}

export default SelectSymbolModal
