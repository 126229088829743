import { useEffect, useMemo, useContext } from 'react'
import { inject } from 'mobx-react'
import Layout from '@components/user/layout/Layout'
import DashboardChart from '@components/user/dashboard/chart/DashboardChart'
import DashboardSidebar from '@components/user/dashboard/sidebar/DashboardSidebar'
import container from '@container'
import RecentTradeListSider from '@components/user/option-contract/trade/recent/list/sider/RecentTradeListSider'
import TradeStatus from '@models/enum/TradeStatus'
import TradeProvider from '@contexts/trade/TradeProvider'
import TradeContext from '@contexts/trade/TradeContext'

const DashboardPage = (props) => {
    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        setUpReceiveSymbolConfigCallback()
    }, [])

    const setUpReceiveSymbolConfigCallback = () => {
        const apiWebsocketClient = container.get('apiWebsocketClient')
        apiWebsocketClient.addCallback('onReceiveSymbolConfigChange', 'binavictory.service.api.pub', (message) => {
            const { payload, type } = message.body
            if (type !== 'symbolConfig') return
            try {
                logger.info('[Receive Symbol Config]')
                props.symbolListStore.reset()
                payload.config.forEach((symbol) => {
                    props.symbolListStore.add({
                        ...symbol,
                        id: symbol.symbol,
                        tradingRestStartAt: (symbol.tradingRestStartAt) ? new Date(symbol.tradingRestStartAt) : null,
                        tradingRestEndAt: (symbol.tradingRestEndAt) ? new Date(symbol.tradingRestEndAt) : null,
                        dailyRestStartAt: (symbol.dailyRestStartAt) ? new Date(symbol.dailyRestStartAt) : null,
                        dailyRestEndAt: (symbol.dailyRestEndAt) ? new Date(symbol.dailyRestEndAt) : null
                    })
                })
            } catch (e) {
                logger.error('[Receive Symbol Config Error]', e)
            }
        })

        apiWebsocketClient.addCallback('onReceiveTradeComplete-1', `binavictory.service.api.tradeEvent.${props.userStore.id}`, (message) => {
            const { payload, type } = message.body
            if (type !== 'trade.end') return

            logger.info('[Receive Trade End]')
            props.recentTradeListStore.trades.get(payload.tradeId).setData({
                nettProfit: payload.nettProfit,
                status: TradeStatus.of(payload.status).enumKey
            })

            props.userStore.setData({
                availableBalance: payload.totalBalance
            })
        })
    }

    return (
        <TradeProvider>
            <TradeContext.Consumer>
                {({ setToShowRecentTrade }) => {
                    return (
                        <Layout contentWrapperClassName='dashboard-layout' handleOverlayClick={() => setToShowRecentTrade(false)}>
                            <div className='flex flex-col md:flex-row gap-3 p-3'>
                                <RecentTradeListSider />
                                <DashboardChart />
                                <DashboardSidebar />
                            </div>
                        </Layout>
                    )
                }}
            </TradeContext.Consumer>
        </TradeProvider>
    )
}

export default inject('userStore', 'symbolListStore', 'recentTradeListStore')(DashboardPage)
