import { useNavigate } from 'react-router-dom'
import Layout from '@components/user/auth/layout/AuthLayout'
import RegisterFormWrapper from '@components/user/auth/register/form/wrapper/RegisterFormWrapper'

const RegisterPage = () => {
    const navigate = useNavigate()

    const handleSuccessRegister = () => {
        navigate('/login', {
            state: {
                registered: true
            }
        })
    }

    return (
        <Layout>
            <RegisterFormWrapper
                onSuccessSubmit={handleSuccessRegister}
            />
        </Layout>
    )
}

export default RegisterPage
