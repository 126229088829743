import { Container, injectable, decorate, inject } from 'inversify'
import 'reflect-metadata'
import config from '@config'
import WebClient from '@utils/WebClient'
import MockWebClient from '@utils/MockWebClient'
import UserService from '@services/UserService'
import SymbolService from '@services/SymbolService'
import MarketService from '@services/MarketService'
import TradeService from '@services/TradeService'
import { createUserStore } from 'models/user'
import { createSymbolListStore } from '@models/symbolList'
import { createRecentTradeListStore } from '@models/recentTradeList'
import Socket from '@utils/Socket'
import logger from '@utils/logger'

const container = new Container()
container.bind('config').toConstantValue(config)

if (config.app.mockApi) {
    // const options = {
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     },
    //     timeout: 30000
    // }

    // const mockEndpointModule = await import('api-mock/src/endpoints')
    // const endpoints = mockEndpointModule.default

    // const webClient = new MockWebClient(options, endpoints)
    // container.bind('webClient').toConstantValue(webClient)
} else {
    const options = {
        baseURL: config.app.apiUrl,
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        timeout: 30000
    }

    const webClient = new WebClient(options)
    container.bind('webClient').toConstantValue(webClient)
}

// Logger
logger.setLevel(logger[config.app.logLevel])
container.bind('logger').toConstantValue(logger)

// Websocket
const apiWebsocketUrl = config.app.apiWebsocketUrl
const apiWebsocketClient = new Socket(apiWebsocketUrl)
container.bind('apiWebsocketClient').toConstantValue(apiWebsocketClient)

// Services
const userService = new UserService(container.get('webClient'))
container.bind('userService').toConstantValue(userService)

const symbolService = new SymbolService(container.get('webClient'))
container.bind('symbolService').toConstantValue(symbolService)

const marketService = new MarketService(container.get('webClient'))
container.bind('marketService').toConstantValue(marketService)

const tradeService = new TradeService(container.get('webClient'))
container.bind('tradeService').toConstantValue(tradeService)

// Models
const userStore = createUserStore(
    {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        contactPrefix: null,
        contactNumber: null
    },
    {
        userService: container.get('userService'),
        logger
    }
)
container.bind('userStore').toConstantValue(userStore)

const symbolListStore = createSymbolListStore(
    {
        symbols: {}
    },
    {
        symbolService: container.get('symbolService'),
        logger
    }
)
container.bind('symbolListStore').toConstantValue(symbolListStore)

const recentTradeListStore = createRecentTradeListStore(
    {
        trades: {}
    },
    {
        tradeService: container.get('tradeService'),
        logger
    }
)
container.bind('recentTradeListStore').toConstantValue(recentTradeListStore)

export default container
