const getTimeframeFromResolution = (resolution) => {
    if (resolution === '1T') {
        return 'tick'
    }
    if (!resolution.match(/[a-zA-Z]/)) {
        // Convert
        if (Number(resolution) < 60) {
            return resolution + 'm'
        } else {
            let temp = Math.ceil(resolution / 60)
            temp = temp + 'h'
            return temp
        }
    } else {
        // Lowercase
        return resolution.toLowerCase()
    }
}

export default getTimeframeFromResolution
