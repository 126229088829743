import { useState, useEffect, useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Input, Row, Col, Select } from 'antd'
import { inject } from 'mobx-react'
import focusErrorInput from '@helpers/focusErrorInput'
import FormError from '@components/common/form/error/FormError'
import FormSuccess from '@components/common/form/success/FormSuccess'
import schema from './schema'
import SubmitButton from '@components/common/button/submit/SubmitButton'
import countries from '@constants/countries'
import container from '@container'

const { Option } = Select

const RegisterForm = props => {
    const [form] = Form.useForm()
    const [submitting, setSubmitting] = useState(false)
    const [apiErrorMessages, setApiErrorMessages] = useState([])
    const [successMessage, setSuccessMessage] = useState(null)
    const { handleSubmit, setError, control, reset, watch, setFocus, trigger, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange'
    })

    useEffect(() => {
    }, [])

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    const onSubmit = async (data) => {
        try {
            setSubmitting(true)
            setApiErrorMessages([])
            setSuccessMessage(null)

            const res = await props.userStore.register(
                data.lastName,
                data.firstName,
                data.country,
                data.email,
                data.password
            )

            if (res.ok) {
                const data = res.data
                if (props.onSuccessSubmit) props.onSuccessSubmit(data)
            } else if (res.status === 422) {
                if (res.data?.errors) {
                    const inputFields = Object.keys(watch())
                    const errors = res.data.errors
                    const { nonErrorFieldMessages } = focusErrorInput(inputFields, errors, setFocus, setError)
                    setApiErrorMessages(nonErrorFieldMessages)
                }
            } else {
                setApiErrorMessages(['Something went wrong'])
            }
        } catch (error) {
            logger.error(error)
        } finally {
            setSubmitting(false)
        }
    }

    return (
        <Form
            className={cn(props.className)}
            style={props.style}
            layout='vertical'
            onFinish={handleSubmit(onSubmit)}
            autoComplete='off'
            form={form}
        >
            {apiErrorMessages.length !== 0 &&
            <FormError className='mb-3' messages={apiErrorMessages} />}

            {successMessage &&
            <FormSuccess className='mb-3' message={successMessage} />}

            <Row gutter={16}>
                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4'
                        label='Last Name'
                        validateStatus={(errors?.lastName?.message) ? 'error' : '-'}
                        help={errors?.lastName?.message}
                    >
                        <Controller
                            name='lastName'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='lastName'
                                    className='input-text-full'
                                    placeholder='Last Name'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>

                <Col xs={24} md={12} lg={12} span={12}>
                    <Form.Item
                        className='mb-4'
                        label='First Name'
                        validateStatus={(errors?.firstName?.message) ? 'error' : '-'}
                        help={errors?.firstName?.message}
                    >
                        <Controller
                            name='firstName'
                            control={control}
                            render={({ field: { value, onChange, ref } }) => (
                                <Input
                                    ref={ref}
                                    name='firstName'
                                    className='input-text-full'
                                    placeholder='First Name'
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item
                label='Country'
                validateStatus={(errors?.country?.message) ? 'error' : '-'}
                help={errors?.country?.message}
            >
                <Controller
                    name='country'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Select
                            ref={ref}
                            name='country'
                            placeholder='Country'
                            showSearch
                            optionFilterProp='children'
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            onChange={onChange}
                            value={value}
                        >
                            {countries.map((item) => {
                                return (
                                    <Option value={item.code} key={item.code}>
                                        {item.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Email'
                validateStatus={(errors?.email?.message) ? 'error' : '-'}
                help={errors?.email?.message}
            >
                <Controller
                    name='email'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            name='email'
                            className='input-text-full'
                            placeholder='Email'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Password'
                validateStatus={(errors?.password?.message) ? 'error' : '-'}
                help={errors?.password?.message}
            >
                <Controller
                    name='password'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            type='password'
                            name='password'
                            className='input-text-full'
                            placeholder='Password'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <Form.Item
                className='mb-4'
                label='Password Confirmation'
                validateStatus={(errors?.passwordConfirmation?.message) ? 'error' : '-'}
                help={errors?.passwordConfirmation?.message}
            >
                <Controller
                    name='passwordConfirmation'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Input
                            ref={ref}
                            type='password'
                            name='passwordConfirmation'
                            className='input-text-full'
                            placeholder='Password Confirmation'
                            onChange={onChange}
                            value={value}
                        />
                    )}
                />
            </Form.Item>

            <SubmitButton className='mt-1' loading={submitting}>
                Register
            </SubmitButton>
        </Form>
    )
}

RegisterForm.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

RegisterForm.defaultProps = {
    onSuccessSubmit: () => {}
}

export default inject('userStore')(RegisterForm)
