import { types, flow, getEnv } from 'mobx-state-tree'
import { SymbolStore } from './symbol'

export const SymbolListStore = types
    .model('SymbolListStore', {
        initiated: types.optional(types.boolean, false),
        symbols: types.optional(types.map(SymbolStore), {})
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        },

        add: flow(function * addSymbol (item) {
            if (self.symbols.has(item.id)) return
            const i = SymbolStore.create(item)
            self.symbols.set(item.id, i)
        }),

        remove: flow(function * removeSymbol (id) {
            if (!self.symbols.has(id)) return
            self.symbols.delete(id)
        }),

        reset () {
            self.symbols = {}
        }
    }))

let _store

export const createSymbolListStore = (data, env = null) => {
    if (!_store) {
        _store = SymbolListStore.create(data, env)
    }
    return _store
}
