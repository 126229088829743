const symbolTypes = [
    {
        name: 'all',
        value: 'all'
    },
    {
        name: 'baskets',
        value: 'baskets'
    },
    {
        name: 'crypto',
        value: 'crypto'
    },
    {
        name: 'forex',
        value: 'forex'
    },
    {
        name: 'commodity',
        value: 'commodity'
    },
    {
        name: 'index',
        value: 'index'
    }
]

export default symbolTypes
