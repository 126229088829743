import { Enumify } from 'enumify'

export default class TradeStatus extends Enumify {
    value = null

    constructor (value = null, label = null, sortWeight = null) {
        super()
        this.value = value
        this.label = label
        this.sortWeight = sortWeight
    }

    static of (type) {
        return TradeStatus.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return TradeStatus.ACTIVE
            case 1: return TradeStatus.WIN
            case 2: return TradeStatus.LOSE
        }
        return null
    }
}

TradeStatus.ACTIVE = new TradeStatus(0, 'Active', 0)
TradeStatus.WIN = new TradeStatus(1, 'Win', 1)
TradeStatus.LOSE = new TradeStatus(2, 'Lose', 1)
TradeStatus._ = TradeStatus.closeEnum()
