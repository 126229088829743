import { string, object, ref } from 'yup'

const schema = object().shape({
    token: string()
        .required('Token is a required field'),
    password: string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is a required field')
})

export default schema
