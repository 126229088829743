import { types, flow, getEnv } from 'mobx-state-tree'
import { TradeStore } from './trade'
import moment from 'moment'
import TradeStatus from './enum/TradeStatus'

export const RecentTradeListStore = types
    .model('RecentTradeListStore', {
        initiated: types.optional(types.boolean, false),
        trades: types.optional(types.map(TradeStore), {}),
        lastPlaceTradeAt: types.maybeNull(types.Date),
        lastTradeEndAt: types.maybeNull(types.Date)
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        },

        load: flow(function * load (accessToken) {
            const logger = getEnv(self).logger
            try {
                const tradeService = getEnv(self).tradeService
                const res = yield tradeService.getRecentList(accessToken)
                if (res.ok) {
                    const data = res.data.data
                    if (data.length > 0) {
                        const latestTrade = data[0]
                        self.lastPlaceTradeAt = new Date(latestTrade.createdAt)
                    }

                    const trades = data.sort((a, b) => {
                        return moment(b.tradeOpenAt).diff(a.tradeOpenAt)
                    }).sort((a, b) => {
                        return TradeStatus.of(a.status).sortWeight - TradeStatus.of(b.status).sortWeight
                    })

                    self.trades.clear()
                    trades.forEach((item) => {
                        item.tradeOpenAt = new Date(item.tradeOpenAt)
                        item.tradeEndAt = new Date(item.tradeEndAt)
                        item.createdAt = new Date(item.createdAt)
                        const i = TradeStore.create(item)
                        self.trades.set(item.id, i)
                    })

                    return trades
                }
                return []
            } catch (e) {
                logger.error('[Failed to get recent trades]', e)
                return []
            }
        }),

        add: flow(function * addSymbol (item) {
            if (self.trades.has(item.id)) return
            const i = TradeStore.create(item)
            self.trades.set(item.id, i)
        }),

        remove: flow(function * removeSymbol (id) {
            if (!self.trades.has(id)) return
            self.trades.delete(id)
        }),

        reset () {
            self.trades = {}
        }
    }))

let _store

export const createRecentTradeListStore = (data, env = null) => {
    if (!_store) {
        _store = RecentTradeListStore.create(data, env)
    }
    return _store
}
