import { string, object, ref } from 'yup'

const schema = object().shape({
    firstName: string()
        .required('First name is a required field'),
    lastName: string()
        .required('Last name is a required field'),
    country: string()
        .required('Country is a required field'),
    email: string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    password: string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is a required field'),
    passwordConfirmation: string()
        .required('Password confirmation is a required field')
        .oneOf([ref('password')], 'Password must match')
})

export default schema
