import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Radio, InputNumber, Form } from 'antd'
import { useFormContext, Controller } from 'react-hook-form'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import OptionDurationTypeSelect from '../../select/duration-type/OptionDurationTypeSelect'
import TimeInput from '@components/common/input/time/TimeInput'
import styles from './OptionExpirationFieldset.module.less'
import OptionDurationType from '@models/enum/OptionDurationType'

const minDurationSecondLeft = 31

const OptionExpirationFieldset = (props) => {
    const { watch, control, setError, setFocus, clearErrors, formState: { errors } } = useFormContext()
    const [currentTime, setCurrentTime] = useState(moment())

    useEffect(() => {
        const update = () => {
            setCurrentTime(moment())
        }

        update()

        const interval = setInterval(() => {
            update()
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (watch('expiredBy') !== 'DURATION') {
            clearErrors('duration')
            return
        }
        if (!watch('durationType') || !watch('duration')) return
        if (errors?.endTime && (errors.duration.type !== 'minDurationSecondLeft')) return

        const durationUnit = OptionDurationType.of(watch('durationType')).unit
        const tradeEndAt = moment().add(watch('duration'), durationUnit).startOf('minute')
        const d = moment.duration(tradeEndAt.diff(currentTime))
        const durationSecondLeft = Math.round(d.asSeconds())

        if (minDurationSecondLeft > durationSecondLeft) {
            const temp = durationSecondLeft > 1 ? 'seconds' : 'second'
            setError('duration', {
                type: 'minDurationSecondLeft',
                message: `${durationSecondLeft} ${temp} to next minute. Please wait`
            })
        } else {
            clearErrors('duration')
        }
    }, [currentTime, errors, watch('expiredBy'), watch('durationType'), watch('duration')])

    useEffect(() => {
        if (watch('expiredBy') !== 'END_TIME') {
            clearErrors('endTime')
            return
        }
        if (!watch('endTime')) return
        if (errors?.endTime && (errors.endTime.type !== 'minDurationSecondLeft' && errors.endTime.type !== 'endTimeExpired')) return

        const tradeEndAt = moment(watch('endTime'))
        const d = moment.duration(tradeEndAt.diff(currentTime))
        const durationSecondLeft = Math.round(d.asSeconds())

        if (durationSecondLeft >= 0) {
            if (minDurationSecondLeft > durationSecondLeft) {
                const temp = durationSecondLeft > 1 ? 'seconds' : 'second'
                setError('endTime', {
                    type: 'minDurationSecondLeft',
                    message: `End time is expiring in ${durationSecondLeft} ${temp}. Please select another time`
                })
            } else {
                clearErrors('endTime')
            }
        } else {
            setError('endTime', {
                type: 'endTimeExpired',
                message: 'End time is expired. Please select another time'
            })
        }
    }, [currentTime, errors, watch('expiredBy'), watch('endTime')])

    return (
        <fieldset className={cn(props.className)} style={props.style}>
            <Form.Item
                className='mb-0'
                validateStatus={(errors?.expiredBy?.message) ? 'error' : '-'}
                help={errors?.expiredBy?.message}
            >
                <Controller
                    name='expiredBy'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <Radio.Group
                            className='mb-3'
                            buttonStyle='solid'
                            optionType='button'
                            onChange={(e) => {
                                onChange(e.target.value)
                                clearErrors('duration')
                                clearErrors('endTime')
                            }}
                            value={value}
                        >
                            <Radio.Button value='DURATION'>
                                Duration
                            </Radio.Button>

                            <Radio.Button value='END_TIME'>
                                End Time
                            </Radio.Button>
                        </Radio.Group>
                    )}
                />
            </Form.Item>

            {watch('expiredBy') === 'DURATION' &&
            <>
                <Form.Item
                    className='mb-3'
                    validateStatus={(errors?.durationType?.message) ? 'error' : '-'}
                    help={errors?.durationType?.message}
                >
                    <Controller
                        name='durationType'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <OptionDurationTypeSelect
                                onChange={(option) => {
                                    onChange(option.value)
                                }}
                                value={value}
                            />
                        )}
                    />
                </Form.Item>

                <Form.Item
                    className='mb-0'
                    validateStatus={(errors?.duration?.message) ? 'error' : '-'}
                    // help={errors?.duration?.message}
                >
                    <Controller
                        name='duration'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <InputNumber
                                ref={ref}
                                className='w-full'
                                controls={{ upIcon: <FontAwesomeIcon icon={faPlus} />, downIcon: <FontAwesomeIcon icon={faMinus} /> }}
                                onChange={onChange}
                                value={value}
                            />
                        )}
                    />

                    {errors?.duration?.message &&
                    <div className={styles.durationErrorMessage}>
                        {errors.duration.message}
                    </div>}
                </Form.Item>
            </>}

            {watch('expiredBy') === 'END_TIME' &&
            <>
                <Form.Item
                    className='mb-0'
                    validateStatus={(errors?.endTime?.message) ? 'error' : '-'}
                    // help={errors?.endTime?.message}
                >
                    <Controller
                        name='endTime'
                        control={control}
                        render={({ field: { value, onChange, ref } }) => (
                            <TimeInput
                                ref={ref}
                                minuteInterval={5}
                                disabledHours={() => {
                                    const hours = []
                                    for (let i = 0; i < moment().hour(); i += 1) hours.push(i)
                                    return hours
                                }}
                                disabledMinutes={(selectedHour) => {
                                    const minutes = []
                                    if (selectedHour === moment().hour()) {
                                        for (let i = moment().minute(); i >= 0; i -= 1) minutes.push(i)
                                    }
                                    return minutes
                                }}
                                onChange={(time) => {
                                    onChange(time)
                                }}
                            />
                        )}
                    />

                    {errors?.endTime?.message &&
                    <div className={styles.endTimeErrorMessage}>
                        {errors.endTime.message}
                    </div>}
                </Form.Item>
            </>}
        </fieldset>
    )
}

OptionExpirationFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

OptionExpirationFieldset.defaultProps = {
}

export default OptionExpirationFieldset
