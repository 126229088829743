import { createContext } from 'react'
import appContainer from '../../container'

const config = appContainer.get('config')

export const ThemeContext = createContext({
    theme: config.app.defaultTheme
})

export default ThemeContext
