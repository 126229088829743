import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Table, Pagination } from 'antd'
import numeral from 'numeral'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpWideShort, faArrowDownWideShort } from '@fortawesome/free-solid-svg-icons'
import styles from './TradeList.module.less'
import TradeSide from '@models/enum/TradeSide'
import TradeStatus from '@models/enum/TradeStatus'

const columns = [
    {
        title: 'Symbol',
        dataIndex: ['symbol', 'side'],
        render: (text, record, index) => {
            return (
                <b>
                    {record.symbol.toUpperCase()}

                    {record.side === TradeSide.RISE.enumKey &&
                    <div className={styles.sideRise}>
                        <FontAwesomeIcon icon={faArrowUpWideShort} className='mr-1' />

                        {TradeSide.RISE.label}
                    </div>}

                    {record.side === TradeSide.FALL.enumKey &&
                    <div className={styles.sideFall}>
                        <FontAwesomeIcon icon={faArrowDownWideShort} className='mr-1' />

                        {TradeSide.FALL.label}
                    </div>}
                </b>
            )
        }
    },
    {
        title: 'Ref.ID',
        dataIndex: 'id'
    },
    {
        title: 'Price',
        dataIndex: 'price',
        render: (text) => {
            return numeral(text).format('$0,0.00')
        }
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        render: (text, record) => {
            return <span className={cn(styles.status, styles[TradeStatus.of(record.status)?.enumKey])}>{numeral(record.amount).format('$0,0.00')}</span>
        }
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (text) => {
            return <span className={cn(styles.status, styles[TradeStatus.of(text)?.enumKey])}>{TradeStatus.of(text)?.label}</span>
        }
    },
    {
        title: 'Start Time',
        dataIndex: 'tradeOpenAt',
        render: (text) => {
            return <span className='text-gray-400'>{moment.utc(text).local().format('D MMM YYYY, hh:mm:ss A')}</span>
        }
    },
    {
        title: 'End Time',
        dataIndex: 'tradeEndAt',
        render: (text) => {
            return <span className='text-gray-400'>{moment.utc(text).local().format('D MMM YYYY, hh:mm:ss A')}</span>
        }
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        render: (text) => {
            return <span className='text-gray-400'>{moment.utc(text).local().format('D MMM YYYY, hh:mm:ss A')}</span>
        }
    }
]

const TradeList = (props) => {
    return (
        <>
            <Table
                className={cn(props.className, styles.tradeList)}
                style={props.style}
                columns={columns}
                dataSource={props.trades}
                loading={props.loading}
                bordered
                size='small'
                scroll={{ x: '100%' }}
                pagination={false}
            />

            {!props.loading &&
            <Pagination
                className='mt-3'
                current={props.pagination?.current}
                pageSize={props.pagination?.pageSize}
                total={props.pagination?.total}
                onChange={props.handlePaginationChange}
            />}
        </>
    )
}

TradeList.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    trades: PropTypes.array,
    loading: PropTypes.bool,
    pagination: PropTypes.object,
    handlePaginationChange: PropTypes.func
}

TradeList.defaultProps = {
    trades: [],
    loading: true,
    handlePaginationChange: () => {}
}

export default TradeList
