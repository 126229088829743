import { string, object, ref } from 'yup'

const schema = object().shape({
    email: string()
        .email('Email must be a valid email')
        .required('Email is a required field'),
    password: string()
        .required('Password is a required field')
})

export default schema
