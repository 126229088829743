import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Layout from '@components/user/auth/layout/AuthLayout'
import LoginFormWrapper from '@components/user/auth/login/form/wrapper/LoginFormWrapper'

const LoginPage = () => {
    const location = useLocation()
    const navgiate = useNavigate()

    const successMessage = useMemo(() => {
        let msg = null
        if (location?.state) {
            const { passwordReset, registered } = location.state
            if (passwordReset) {
                msg = 'Your password has been changed successfully.'
            } else if (registered) {
                msg = 'Your account has been created successfully.'
            }
        }
        return msg
    }, [location?.state])

    const handleSuccessSubmit = () => {
        navgiate('/dashboard')
    }

    return (
        <Layout>
            <LoginFormWrapper
                successMessage={successMessage}
                onSuccessSubmit={handleSuccessSubmit}
            />
        </Layout>
    )
}

export default LoginPage
