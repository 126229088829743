import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'
import { inject } from 'mobx-react'
import Layout from '@components/user/auth/layout/AuthLayout'
import ResetPasswordFormWrapper from '@components/user/auth/reset-password/form/wrapper/ResetPasswordFormWrapper'

const ResetPasswordPage = (props) => {
    const location = useLocation()
    const navigate = useNavigate()
    const query = qs.parse(location.search, { ignoreQueryPrefix: true })

    useEffect(() => {
        if (!query?.token) {
            navigate('/login')
        }
    }, [])

    const handleSuccessResetPassword = () => {
        navigate('/login', {
            state: {
                passwordReset: true
            }
        })
    }

    return (
        <Layout>
            <ResetPasswordFormWrapper
                defaultValues={{
                    token: query.token
                }}
                onSuccessSubmit={handleSuccessResetPassword}
            />
        </Layout>
    )
}

export default inject('userStore')(ResetPasswordPage)
