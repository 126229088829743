import { types, flow, getEnv } from 'mobx-state-tree'
import jwtDecode from 'jwt-decode'
import WebClientResult from '@utils/WebClientResult'

const DecimalPrimitive = types.custom({
    name: 'Decimal',
    fromSnapshot (value) {
        return parseFloat(value)
    },
    toSnapshot (value) {
        return value.toString()
    },
    isTargetType (value) {
        return parseFloat(value)
    },
    getValidationMessage (value) {
        if (/^-?\d+\.\d+$/.test(value)) return '' // OK
        if (/^\d+$/.test(value)) return '' // OK
        return `'${value}' doesn't look like a valid decimal number`
    }
})

export const UserStore = types
    .model('UserStore', {
        initiated: types.optional(types.boolean, false),
        id: types.maybeNull(DecimalPrimitive, 0),
        firstName: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        contactPrefix: types.maybeNull(types.string),
        contactNumber: types.maybeNull(types.string),
        country: types.maybeNull(types.string),
        availableBalance: types.optional(DecimalPrimitive, 0),
        availableBalanceCurrency: types.optional(types.string, 'MYR'),
        accessToken: types.maybeNull(types.string),
        isLogined: types.optional(types.boolean, false)
    })
    .actions((self) => ({
        setData (data) {
            for (const key in data) {
                self[key] = data[key]
            }
        },

        init () {
            self.initiated = true
        },

        register: flow(function * register (
            lastName,
            firstName,
            country,
            email,
            password
        ) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Register')
                const service = getEnv(self).userService
                const res = yield service.register(
                    lastName,
                    firstName,
                    country,
                    email,
                    password
                )
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to register', error)
                return new WebClientResult(false, null)
            }
        }),

        login: flow(function * login (email, password) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Login')
                const service = getEnv(self).userService
                const res = yield service.login(email, password)

                if (res.ok && res.data) {
                    const data = res.data

                    if (data.auth) {
                        const decoded = jwtDecode(data.accessToken)
                        self.setData({
                            ...decoded,
                            isLogined: true,
                            accessToken: data.accessToken
                        })
                    }
                }

                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to login', error)
                return new WebClientResult(false, null)
            }
        }),

        getAccountSummary: flow(function * getAccountSummary (accessToken) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Get account summary')
                const service = getEnv(self).userService
                const res = yield service.getAccountSummary(accessToken)
                if (res.ok && res.data) {
                    const data = res.data
                    self.setData(data)
                }
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to get account summary', error)
                return new WebClientResult(false, null)
            }
        }),

        checkToken: flow(function * checkToken (accessToken) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Check token')
                const service = getEnv(self).userService
                const res = yield service.checkToken(accessToken)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to check token', error)
                return new WebClientResult(false, null)
            }
        }),

        requestResetPassword: flow(function * requestResetPassword (email) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Request reset password')
                const service = getEnv(self).userService
                const res = yield service.requestResetPassword(email)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to request reset password', error)
                return new WebClientResult(false, null)
            }
        }),

        resetPassword: flow(function * resetPassword (token, password) {
            const logger = getEnv(self).logger
            try {
                logger.info('[User] Reset password')
                const service = getEnv(self).userService
                const res = yield service.resetPassword(token, password)
                return new WebClientResult(res.ok, res.data, res.status)
            } catch (error) {
                logger.error('[User] Failed to reset password', error)
                return new WebClientResult(false, null)
            }
        }),

        logout: flow(function * logout () {
            const logger = getEnv(self).logger
            logger.info('[User] Logout')
            self.id = null
            self.firstName = null
            self.lastName = null
            self.email = null
            self.contactPrefix = null
            self.contactNumber = null
            self.country = null
            self.availableBalance = 0
            self.availableBalanceCurrency = 'MYR'
            self.accessToken = null
            self.isLogined = false
        })
    }))

let _store

export const createUserStore = (data, env = null) => {
    if (!_store) {
        _store = UserStore.create(data, env)
    }
    return _store
}
