import { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Header from './header/Header'
import Footer from './footer/Footer'
import styles from './Layout.module.less'
import AppContext from '@contexts/app/AppContext'

const Layout = (props) => {
    const { toShowLayoutOverlay } = useContext(AppContext)

    return (
        <div className={cn(props.className, styles.wrapper)}>
            {props.showHeader &&
            <Header />}

            <div className={cn(styles.contentWrapper, props.contentWrapperClassName)}>
                {props.children}
            </div>

            {props.showFooter &&
            <Footer />}

            <div
                onClick={props.handleOverlayClick}
                className={cn(
                    styles.overlay,
                    { [styles.showOverlay]: toShowLayoutOverlay }
                )}
            />
        </div>
    )
}

Layout.propTypes = {
    showHeader: PropTypes.bool,
    showFooter: PropTypes.bool,
    handleOverlayClick: PropTypes.func
}

Layout.defaultProps = {
    showHeader: true,
    showFooter: true,
    handleOverlayClick: () => {}
}

export default Layout
