import { Enumify } from 'enumify'

export default class TradeSide extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (side) {
        return TradeSide.enumValueOf(side)
    }

    static for (side) {
        switch (side) {
            case 0: return TradeSide.FALL
            case 1: return TradeSide.RISE
        }
        return null
    }
}

TradeSide.FALL = new TradeSide(0, 'Fall')
TradeSide.RISE = new TradeSide(1, 'Rise')
TradeSide._ = TradeSide.closeEnum()
