import { useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Radio } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons'
import ThemeContext from '@contexts/theme/ThemeContext'

const ThemeTabPane = (props) => {
    const { theme, setTheme } = useContext(ThemeContext)

    const handleThemeChange = ({ target: { value } }) => {
        setTheme(value)
    }

    return (
        <div
            className={cn(props.className)}
            style={props.style}
        >
            <div className='font-bold mb-2'>Select Theme</div>

            <Radio.Group value={theme} buttonStyle='solid' optionType='button' onChange={handleThemeChange}>
                <Radio.Button value='DARK'>
                    <FontAwesomeIcon icon={faMoon} className='mr-2' />
                    Dark
                </Radio.Button>
                <Radio.Button value='LIGHT'>
                    <FontAwesomeIcon icon={faSun} className='mr-2' />
                    Light
                </Radio.Button>
            </Radio.Group>
        </div>
    )
}

ThemeTabPane.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

ThemeTabPane.defaultProps = {
}

export default ThemeTabPane
