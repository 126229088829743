import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import moment from 'moment'
import 'moment-timezone'
import { Tooltip } from 'antd'
import { useLocation } from 'react-router-dom'
import { SettingOutlined } from '@ant-design/icons'
import styles from './Footer.module.less'
import AppContext from '@contexts/app/AppContext'
import TradeContext from '@contexts/trade/TradeContext'

const Footer = (props) => {
    const location = useLocation()
    const { setToShowRecentTrade } = useContext(TradeContext)
    const [currentTime, setCurrentTime] = useState(moment())
    const { setIsSettingModalVisible } = useContext(AppContext)

    useEffect(() => {
        const update = () => {
            setCurrentTime(moment())
        }

        update()

        const interval = setInterval(() => {
            update()
        }, 1000)

        return () => clearInterval(interval)
    }, [])

    return (
        <footer className={cn(styles.footer)} style={props.style}>
            <div className={styles.rightMenu}>
                {location.pathname.indexOf('/dashboard') === 0 &&
                <button type='button' className={styles.btnShowRecentTrade} onClick={() => setToShowRecentTrade(true)}>
                    Show Recent Trade
                </button>}
            </div>

            <div className={styles.leftMenu}>
                <div className={styles.time}>
                    {currentTime.format('YYYY-MM-DD hh:mm:ss')}
                </div>

                <nav>
                    <ul className={styles.navList}>
                        <li onClick={() => setIsSettingModalVisible(true)}>
                            <Tooltip placement='topRight' title='Settings'>
                                <SettingOutlined />
                            </Tooltip>
                        </li>
                    </ul>
                </nav>
            </div>
        </footer>
    )
}

Footer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

Footer.defaultProps = {
}

export default Footer
