import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import styles from './Header.module.less'
import { Link } from 'react-router-dom'
import UserAccountDropdown from '@components/user/account/dropdown/UserAccountDropdown'
import PrimaryButton from '@components/common/button/primary/PrimaryButton'

const Header = (props) => {
    return (
        <header className={cn(styles.header, props.className)} style={props.style}>
            <nav className={styles.menuLeft}>
                <div />
            </nav>
            <nav className={styles.menuRight}>
                {props.userStore.isLogined &&
                <>
                    <UserAccountDropdown />

                    <PrimaryButton htmlType='button'>
                        Deposit
                    </PrimaryButton>
                </>}

                {!props.userStore.isLogined &&
                <>
                    <Link to='/login'>
                        <Button type='link' htmlType='button'>
                            Log In
                        </Button>
                    </Link>

                    <Link to='/sign-up'>
                        <Button type='primary' htmlType='button'>
                            Sign Up
                        </Button>
                    </Link>
                </>}
            </nav>
        </header>
    )
}

Header.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

Header.defaultProps = {
}

export default inject('userStore')(observer(Header))
