import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { Form, InputNumber } from 'antd'
import { useFormContext, Controller } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'

const OptionAmountFieldset = (props) => {
    const { watch, control, formState: { errors } } = useFormContext()

    useEffect(() => {
    }, [])

    return (
        <fieldset className={cn(props.className)} style={props.style}>
            <Form.Item
                className='mb-0'
                label='Invest Amount'
                validateStatus={(errors?.amount?.message) ? 'error' : '-'}
                help={errors?.amount?.message}
            >
                <Controller
                    name='amount'
                    control={control}
                    render={({ field: { value, onChange, ref } }) => (
                        <InputNumber
                            ref={ref}
                            className='w-full'
                            controls={{ upIcon: <FontAwesomeIcon icon={faPlus} />, downIcon: <FontAwesomeIcon icon={faMinus} /> }}
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            onChange={(e) => {
                                onChange(e)
                            }}
                            value={value}
                        />
                    )}
                />
            </Form.Item>
        </fieldset>
    )
}

OptionAmountFieldset.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

OptionAmountFieldset.defaultProps = {
}

export default OptionAmountFieldset
