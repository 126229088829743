import { Enumify } from 'enumify'

export default class ChartType extends Enumify {
    value = null

    constructor (value = null, label = null) {
        super()
        this.value = value
        this.label = label
    }

    static of (type) {
        return ChartType.enumValueOf(type)
    }

    static for (type) {
        switch (type) {
            case 0: return ChartType.BAR
            case 1: return ChartType.CANDLE
            case 2: return ChartType.LINE
            case 3: return ChartType.AREA
            case 4: return ChartType.RENKO
            case 5: return ChartType.KAGI
            case 6: return ChartType.PNF
            case 7: return ChartType.LNE_BREAK
            case 8: return ChartType.HEIKIN_AISHI
            case 9: return ChartType.HOLLOW_CANDLE
            case 10: return ChartType.BASELINE
            case 12: return ChartType.HI_LO
            case 13: return ChartType.COLUMN
        }
        return null
    }
}

ChartType.BAR = new ChartType(0, 'Bar')
ChartType.CANDLE = new ChartType(1, 'Candle')
ChartType.LINE = new ChartType(2, 'Line')
ChartType.AREA = new ChartType(3, 'Area')
ChartType.RENKO = new ChartType(4, 'Renko')
ChartType.KAGI = new ChartType(5, 'Kagi')
ChartType.PNF = new ChartType(6, 'PnF')
ChartType.LINE_BREAK = new ChartType(7, 'Line Break')
ChartType.HEIKIN_AISHI = new ChartType(8, 'Heikn-Aishi')
ChartType.HOLLOW_CANDLE = new ChartType(9, 'Hollow Candle')
ChartType.BASELINE = new ChartType(10, 'Baseline')
ChartType.HI_LO = new ChartType(12, 'Hi-Lo')
ChartType.COLUMN = new ChartType(13, 'Column')
ChartType._ = ChartType.closeEnum()
