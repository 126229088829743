import countryList from 'country-list'

const countries = countryList.getData()
countries.sort(function (a, b) {
    if (a.name < b.name) {
        return -1
    }
    if (a.name > b.name) {
        return 1
    }
    return 0
})
const index = countries.findIndex((country) => {
    return country.code === 'MY'
})
const malaysia = countries[index]
countries.splice(index, 1)
countries.unshift(malaysia)

export default countries
