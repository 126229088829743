import _ from 'lodash'

const focusErrorInput = (inputKeys, errors, setFocusHandler, setErrorHandler, excludeFocusKeys = [], keysToReplace = []) => {
    const nonErrorFieldMessages = []
    const errorKeys = Object.keys(errors)

    let toFocus = false
    errorKeys.forEach((key, index) => {
        const firstErrorMsg = errors[key][0]
        let keyToFocus = key
        const keyToReplaceIndex = _.findIndex(keysToReplace, { from: key })
        if (keyToReplaceIndex !== -1) {
            keyToFocus = keysToReplace[keyToReplaceIndex].to
        }
        if (inputKeys.includes(keyToFocus)) {
            if ((index === 0 || toFocus) && !excludeFocusKeys.includes(keyToFocus)) {
                setFocusHandler(keyToFocus)
                toFocus = false
            }
            setErrorHandler(keyToFocus, {
                type: 'manual',
                message: firstErrorMsg
            })
        } else {
            nonErrorFieldMessages.push(firstErrorMsg)
            toFocus = (index === 0)
        }
    })

    return { nonErrorFieldMessages }
}

export default focusErrorInput
