import React, { useMemo } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import RegisterForm from '../RegisterForm'
import styles from './RegisterFormWrapper.module.less'

const RegisterFormWrapper = props => {
    return (
        <div className={cn(styles.wrapper, props.className)} style={props.style}>
            <h1>Register</h1>
            <p>It's good to see you. Register now and start trading.</p>

            <RegisterForm
                className='mt-5 mb-3 w-full'
                onSuccessSubmit={props.onSuccessSubmit}
            />

            <p className='mb-4'>
                Already have an account yet?
                <Link
                    to='/login'
                    className='ml-1'
                >
                    Login
                </Link>
            </p>
        </div>
    )
}

RegisterFormWrapper.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    onSuccessSubmit: PropTypes.func
}

RegisterFormWrapper.defaultProps = {
    onSuccessSubmit: () => {}
}

export default RegisterFormWrapper
