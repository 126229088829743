import { useState, useEffect, useMemo } from 'react'
import { Route, Navigate, useLocation, matchRoutes } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Spin, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import container from '@container'

const UserPrivateRoute = ({ children, userStore, restricted, ...restProps }) => {
    const [isAuthed, setIsAuthed] = useState(false)
    const [checking, setChecking] = useState(true)
    const location = useLocation()

    const logger = useMemo(() => {
        return container.get('logger')
    }, [])

    useEffect(() => {
        if (userStore?.initiated) {
            if (userStore?.accessToken) {
                checkAccessToken(userStore?.accessToken)
            } else {
                setChecking(false)
                setIsAuthed(false)
            }
        }
    }, [userStore?.initiated, userStore.accessToken, userStore.isLogined])

    useEffect(() => {
        if (isAuthed) {
            const apiWebsocketClient = container.get('apiWebsocketClient')
            apiWebsocketClient.setupListener()
        }
    }, [isAuthed])

    const checkAccessToken = async (accessToken) => {
        try {
            setChecking(true)
            const res = await userStore.checkToken(accessToken)
            if (res.ok && res.data) {
                const apiWebsocketClient = container.get('apiWebsocketClient')
                apiWebsocketClient.setAuthToken(accessToken)
                apiWebsocketClient.setUserId(res.data.id)
                await userStore.getAccountSummary(accessToken)

                setIsAuthed(res.ok)
            } else if (res.status === 401) {
                userStore.logout()
                setIsAuthed(false)
                Modal.error({
                    title: 'Error',
                    content: (
                        <p>Invalid Access Token</p>
                    )
                })
            } else {
                userStore.logout()
                setIsAuthed(false)
                Modal.error({
                    title: 'Error',
                    content: (
                        <p>Something went wrong</p>
                    )
                })
            }
        } catch (error) {
            userStore.logout()
            setIsAuthed(false)
            Modal.error({
                title: 'Error',
                content: (
                    <p>Something went wrong</p>
                )
            })
            logger.error('[Check Access Token] Failed', error)
        } finally {
            setTimeout(() => {
                setChecking(false)
            }, 2000)
        }
    }

    if (checking) {
        return (
            <div className='flex flex-col w-full h-screen justify-center content-center place-content-center'>
                <Spin size='large' />
            </div>
        )
    } else if (!isAuthed) {
        return <Navigate to={restProps.redirectTo} replace />
    } else {
        return children
    }
}

UserPrivateRoute.propTypes = {
    restricted: PropTypes.bool,
    redirectTo: PropTypes.object
}

UserPrivateRoute.defaultProps = {
    restricted: false,
    redirectTo: {
        pathname: '/login'
    }
}

export default inject('userStore')(observer(UserPrivateRoute))
