import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './AuthLayout.module.less'

const AuthLayout = (props) => {
    return (
        <div className={cn(props.className, styles.wrapper)} style={props.style}>
            <div className={styles.contentWrapper}>
                {props.children}
            </div>

            <ul class={styles.circles}>
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
                <li />
            </ul>
        </div>
    )
}

AuthLayout.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object
}

AuthLayout.defaultProps = {
}

export default AuthLayout
